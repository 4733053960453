/* NOTE
 Should be the only library to use to manipulate and display date and time
 considered as a facade on date management provider (like date-fns) with some
 custom behaviors;
*/

import { formatDateLocalized, getLocale } from "@secuis/ccp-react-components";
import { addDays, compareAsc, endOfDay, endOfMonth, formatDuration as dateFnsFormatDuration, isThisYear, isToday, isYesterday, startOfMonth } from "date-fns";

import { i18next } from "../../i18next";
import { DATE_FNS_MONTH_WIDTH_MAP, DATE_TIME_VARIANTS_MAP } from "./consts";
import { DateOnlyVariant, DateTimeVariant, DateType, MonthNumber, MonthWidth, TimeOnlyVariant } from "./types";

const mapDateToDateObject = (date: DateType): Date => (typeof date === "string" || typeof date === "number" ? new Date(date) : date);

const formatLocalized = (date: DateType, formatString: string): string => {
    const locale = i18next.language;

    return formatDateLocalized(mapDateToDateObject(date), formatString, locale);
};

export const formatDateTime = (date: DateType, variant: DateTimeVariant = "midDateTime"): string => {
    return formatLocalized(mapDateToDateObject(date), DATE_TIME_VARIANTS_MAP[variant]);
};

export const formatDate = (date: DateType, variant: DateOnlyVariant = "midDate"): string => {
    return formatDateTime(date, variant);
};

export const formatTime = (date: DateType, variant: TimeOnlyVariant = "shortTime"): string => {
    return formatDateTime(date, variant);
};

export const formatExplicit = (date: DateType, formatStr: string): string => {
    return formatLocalized(date, formatStr);
};

export const formatFriendly = (date: DateType, dateOnly = false): string => {
    const formattedTime = !dateOnly ? formatTime(date) : "";
    const mappedDate = mapDateToDateObject(date);
    if (isToday(mappedDate)) {
        return `${i18next.t("common.date.today")} ${formattedTime}`.trim();
    }

    if (isYesterday(mappedDate)) {
        return `${i18next.t("common.date.yesterday")} ${formattedTime}`.trim();
    }

    if (isThisYear(mappedDate)) {
        return formatDateTime(mappedDate, dateOnly ? "dayMonthDate" : "dayMonthDateTime");
    }

    // NOTE: Do any kind of predefined formatting
    return formatDateTime(mappedDate, dateOnly ? "longDate" : "midDateTime");
};

export const getDateStringByTimezone = (timeStr: string): string =>
    new Date(new Date(timeStr).getTime() - new Date(timeStr).getTimezoneOffset() * 60 * 1000).toISOString();

export const isStartOfMonth = (date: Date) => startOfMonth(date).toDateString() === date.toDateString();

export const isEndOfMonth = (date: Date) => endOfMonth(date).toDateString() === date.toDateString();

export const isInDateRange = (date: Date, dayOfMonthToCompare: number, dateRangeInDays: number): boolean => {
    const exclusiveDateRangeInDays = dateRangeInDays ? dateRangeInDays - 1 : 0;
    const currentDayOfMonth = date.getDate();
    if (currentDayOfMonth - dayOfMonthToCompare === 0) {
        return true;
    }
    if (currentDayOfMonth - dayOfMonthToCompare > 0) {
        return dayOfMonthToCompare + exclusiveDateRangeInDays >= currentDayOfMonth;
    }
    let dateToCompare = endOfDay(date);
    dateToCompare.setMonth(date.getMonth() - 1);
    dateToCompare.setDate(dayOfMonthToCompare);
    dateToCompare = addDays(dateToCompare, exclusiveDateRangeInDays);
    return dateToCompare >= date;
};

export const formatDuration = (duration: Duration, format: string[]): string => {
    const locale = getLocale(i18next.language);
    return dateFnsFormatDuration(duration, { format, locale });
};

//NOTE: monthNumber as a union with number
// This way IDE suggests option and also allows to provide just a number variable
export const getMonthName = (monthNumber: MonthNumber | number, variant: MonthWidth = "full"): string => {
    return monthNumber < 1 || monthNumber > 12 ? "" : getLocale(i18next.language).localize.month(monthNumber - 1, { width: DATE_FNS_MONTH_WIDTH_MAP[variant] });
};

export const isBeforeOrEqual = (date: Date, dateToCompare: Date) => compareAsc(date, dateToCompare) < 1;

export const isAfterOrEqual = (date: Date, dateToCompare: Date) => compareAsc(date, dateToCompare) > -1;

export const createHourLabelForDate = (date: Date): string => {
    const formattedTime = formatTime(date);
    const formattedHour = formattedTime.split(":")[0].padStart(2, "0");
    const suffixAndTime = formattedTime.split(" ").reverse();
    const suffix = suffixAndTime.length > 1 ? suffixAndTime[0] : "";

    return `${formattedHour} ${suffix}`.trim();
};
