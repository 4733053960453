import { HttpResponse } from "msw";

import { db } from "../../db";
import { getSubcategories } from "../../helpers";
import { GetReportsPayload } from "../../types";

export const getReportsResolver = (requestUrl: string, payload: GetReportsPayload) => {
    const url = new URL(requestUrl);

    const page = parseInt(url.searchParams.get("page") as string);
    const size = parseInt(url.searchParams.get("size") as string);

    const filters = buildFilters(payload);

    const reports = db.reports.findMany({
        where: filters,
        take: size,
        skip: page * size,
        orderBy: {
            createDateTime: "desc",
        },
    });

    const searchCount = db.reports.count({
        where: filters,
    });

    const result = {
        page: url.searchParams.get("page"),
        size: size,
        totalCount: searchCount,
        reports: reports,
    };

    return HttpResponse.json(result);
};

const buildFilters = (requestParams: GetReportsPayload) => {
    // Since it's not possible to use OR/AND in querying with mswjs/data,
    // we retrieve all level 3 subcategories for all requested categories from level 1 and level 2
    // and consolidate them into the categories array.
    const categories = consolidateCategories(requestParams.reportCategories1, requestParams.reportCategories2, requestParams.reportCategories3);

    const locationFilter = {
        locationId: {
            in: requestParams.locationIds,
        },
    };

    const typeFilter = requestParams.reportTypes.length
        ? {
              type: {
                  in: requestParams.reportTypes,
              },
          }
        : {};

    const excludedReportTypes = requestParams.excludedReportTypes.length
        ? {
              type: {
                  notIn: requestParams.excludedReportTypes,
              },
          }
        : {};

    const categoryFilter = categories.length
        ? {
              categoryLevel3: {
                  in: categories,
              },
          }
        : {};

    const siteLocationFilter = requestParams.siteLocations.length
        ? {
              siteLocation: {
                  in: requestParams.siteLocations,
              },
          }
        : {};

    const severityLevelFilter = requestParams.severityLevels.length
        ? {
              severityLevel: {
                  in: requestParams.severityLevels.map((value) => parseInt(value)),
              },
          }
        : {};

    const onlyUnreadFilter =
        requestParams.isReadReport === false
            ? {
                  isRead: {
                      equals: false,
                  },
              }
            : {};

    const reportDateTimeStart = Date.parse(requestParams.reportDateTimeStart);
    const reportDateTimeEnd = Date.parse(requestParams.reportDateTimeEnd);

    const createDateTimeFilter = {
        createDateTime: {
            gte: reportDateTimeStart,
            lt: reportDateTimeEnd,
        },
    };

    return {
        ...locationFilter,
        ...typeFilter,
        ...excludedReportTypes,
        ...categoryFilter,
        ...siteLocationFilter,
        ...severityLevelFilter,
        ...createDateTimeFilter,
        ...onlyUnreadFilter,
    };
};

export const consolidateCategories = (level1Categories: string[], level2Categories: string[], level3Categories: string[]) => {
    const categories = [...level1Categories, ...level2Categories].flatMap((category) => getSubcategories(category));

    return [...new Set([...categories, ...level3Categories])];
};

export default getReportsResolver;
