import { Text } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ISiteObject } from "../../../models/SiteObjectModel";
import { useReportCategoryItems } from "../../../store/filter/FilterHooks";
import { getDamagesCount, getTopDamagesCategories, useDamageReportsRedirection } from "../../../store/insights/DamageReportsHooks";
import LocationsSelectors from "../../../store/locations/LocationsSelectors";
import { NoWrapButtonText, SiteCardItem, SiteCardWrapper, StyledButton, StyledInsightTileHeader } from "./SiteCard.styled";

type PropsType = {
    siteId: string;
    reports: { [k: string]: number };
};

export const SiteCard = ({ siteId, reports }: PropsType) => {
    const { t } = useTranslation();
    const siteObject = useSelector(LocationsSelectors.getAuthorizedLocationById(siteId));
    const { reportCategories } = useReportCategoryItems();
    const otherLabel = t("insights.damages.otherCategory");
    const reportsList = getTopDamagesCategories(reports, reportCategories, otherLabel);
    const { redirectToLocationDamageReports, redirectToCategory2Location } = useDamageReportsRedirection();
    const locationReportsCount = getDamagesCount(reports);
    const tooltipText = t("insights.damages.siteTooltip");

    return (
        <SiteCardWrapper data-testid="damages-section-site-card-container">
            <StyledInsightTileHeader tooltipText={tooltipText} id={siteId} iconVariant="Info" headerText={siteObject?.displayName}>
                <NoWrapButtonText
                    data-testid="damages-number-of-reports-on-site"
                    micro
                    uppercase
                    color="primary"
                    icon="ArrowForward"
                    direction="row-reverse"
                    iconSize="S"
                    onClick={() => {
                        redirectToLocationDamageReports(siteId);
                    }}
                >
                    {t("insights.damages.reports", { count: locationReportsCount })}
                </NoWrapButtonText>
            </StyledInsightTileHeader>
            {reportsList.map(({ category, count, label }, index) => (
                <SiteCardItem key={index}>
                    <Text data-testid="damages-category-name" small>
                        {label}
                    </Text>
                    <StyledButton
                        data-testid="damages-number-of-reports-with-category-lvl2"
                        color="primary"
                        icon="ArrowForward"
                        direction="row-reverse"
                        iconSize="S"
                        type="button"
                        onClick={() => {
                            redirectToCategory2Location(siteId, category);
                        }}
                    >
                        <Text bold small uppercase>
                            {count}
                        </Text>
                    </StyledButton>
                </SiteCardItem>
            ))}
        </SiteCardWrapper>
    );
};

type EmptySiteCardPropsType = {
    allSites: ISiteObject[];
    filterApplied: boolean;
    siteIdsWithDamages?: string[];
    siteIdsWithoutDamages?: string[];
};

export const EmptySiteCard = ({ allSites, filterApplied, siteIdsWithDamages, siteIdsWithoutDamages }: EmptySiteCardPropsType) => {
    const { t } = useTranslation();
    const [headerText, tooltipText] = useMemo(() => {
        if (!filterApplied) {
            if (allSites.length === siteIdsWithoutDamages?.length) {
                if (allSites.length === 1) {
                    return [allSites[0]?.displayName, t("insights.damages.allSitesTooltip")];
                }
                return [t("insights.damages.allSites"), t("insights.damages.allSitesTooltip")];
            }
            return [t("insights.damages.otherSitesNoCount"), t("insights.damages.otherSitesTooltip")];
        }
        if (siteIdsWithDamages.length) {
            return [t("insights.damages.otherSites", { count: siteIdsWithoutDamages?.length }), t("insights.damages.otherSitesTooltip")];
        }
        return [t("insights.damages.yourSitesFiltered", { count: siteIdsWithoutDamages?.length }), t("insights.damages.otherSitesTooltip")];
    }, [allSites, filterApplied, siteIdsWithDamages, siteIdsWithoutDamages, t]);

    return siteIdsWithoutDamages?.length > 0 ? (
        <SiteCardWrapper hasWholeWidth>
            <StyledInsightTileHeader tooltipText={tooltipText} id="empty-card" iconVariant="Info" headerText={headerText}></StyledInsightTileHeader>
            <SiteCardItem>
                <Text small>{t("insights.damages.noDamages")}</Text>
            </SiteCardItem>
        </SiteCardWrapper>
    ) : null;
};
