import { Text, View } from "@react-pdf/renderer";
import { formatDateTime } from "src/helpers/date";
import { DynamicReportType } from "src/models/ReportModel";
import { IUser } from "src/models/UserModel";

import { styles } from "../pdfStyles";
import { PdfViewers } from "./PdfViewers";

type Props = {
    report: DynamicReportType;
    isShared: boolean;
    user: IUser;
};

export const PdfReportDetailsHeaderLine1 = ({ report, isShared, user }: Props) => {
    return (
        <>
            <View style={styles.sectionLineBreak}></View>
            <View style={styles.detailsLine}>
                <View style={styles.detailsWrappableWrapper}>
                    <View>
                        <Text style={styles.headerDate}>{formatDateTime(report.reportDateTime)}</Text>
                    </View>
                    <View style={styles.verticalDivider} />
                    <PdfViewers report={report} isShared={isShared} user={user} />
                </View>
            </View>
        </>
    );
};
