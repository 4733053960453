import { Text } from "@secuis/ccp-react-components";
import millify from "millify";

import { DataCellStyled } from "./DataCell.styles";

export type DataCellProps = {
    categoryIncidentCount: number;
    totalIncidentCount: number;
};

export const DataCell = ({ categoryIncidentCount, totalIncidentCount }: DataCellProps) => {
    return (
        <DataCellStyled categoryIncidentCount={categoryIncidentCount} totalIncidentCount={totalIncidentCount}>
            <Text data-testid="matrix-table-data-cell-count">{millify(categoryIncidentCount)}</Text>
        </DataCellStyled>
    );
};
