import { IsoStringDate } from "src/helpers/date";

import { QueryResult } from "../../../models/DbModel";
import { IKeyValuePair } from "../../../store/insights/InsightsModel";
import { getParameterizedArray, getSqlStringifiedArrayFromObject } from "../queryUtilities";

const getTop5Query = (categoryLevel1: string, siteIds: string[], fromDate: IsoStringDate, toDate: IsoStringDate): { sql: string; params: unknown } => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);
    return {
        sql: `
        SELECT r.category_level2, count(_ROWID_) AS insights_count FROM reports r
        WHERE r.category_level1 = $categoryLevel1
            AND r.report_date_time >= STRFTIME('%Y-%m-%d %H:%M:%S', $fromDate)
            AND r.report_date_time <= STRFTIME('%Y-%m-%d %H:%M:%S', $toDate)
            AND r.location_id IN (${stringifiedSites})
        GROUP BY r.category_level1, r.category_level2
        ORDER BY insights_count desc
        LIMIT 5
    `,
        params: {
            $categoryLevel1: categoryLevel1,
            $fromDate: fromDate,
            $toDate: toDate,
            ...siteIdsParams,
        },
    };
};

const getTop5Results = (queryResults: QueryResult[]): IKeyValuePair<string, number>[] => {
    if (!queryResults?.length) {
        return [];
    }
    return queryResults[0].values.reduce(
        (results, cell) => {
            results.push({ name: cell[0], value: cell[1] });
            return results;
        },
        [] as IKeyValuePair<string, number>[],
    );
};

const bySeverityLevelQuery = (categoryLevel1: string, siteIds: string[], fromDate: IsoStringDate, toDate: IsoStringDate): { sql: string; params: unknown } => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);
    return {
        sql: `
            SELECT r.severity_level, count(_ROWID_) AS insights_count FROM reports r
            WHERE r.category_level1 = $categoryLevel1
                AND r.report_date_time >= STRFTIME('%Y-%m-%d %H:%M:%S', $fromDate)
                AND r.report_date_time <= STRFTIME('%Y-%m-%d %H:%M:%S', $toDate)
                AND r.location_id IN (${stringifiedSites})
            GROUP BY r.category_level1, r.severity_level
            ORDER BY insights_count desc
        `,
        params: {
            $categoryLevel1: categoryLevel1,
            $fromDate: fromDate,
            $toDate: toDate,
            ...siteIdsParams,
        },
    };
};

const getBySeverityLevelResults = (queryResults: QueryResult[]): Record<string, number> => {
    if (!queryResults?.length) {
        return {};
    }
    const groupedBySeverityLevels = queryResults[0].values.reduce((results, cell) => {
        results[cell[0]] = cell[1];
        return results;
    }, {});

    for (let i = 0; i <= 4; i++) {
        if (!groupedBySeverityLevels[i]) {
            groupedBySeverityLevels[i] = 0;
        }
    }

    return groupedBySeverityLevels;
};

const reportsCountQuery = (siteIds: string[], fromDate: IsoStringDate, toDate: IsoStringDate): { sql: string; params: unknown } => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);
    return {
        sql: `
    SELECT count(_ROWID_) AS insights_count
    FROM reports r
    WHERE r.report_date_time >= STRFTIME('%Y-%m-%d %H:%M:%S', $fromDate)
        AND r.report_date_time <= STRFTIME('%Y-%m-%d %H:%M:%S', $toDate)
        AND r.location_id IN (${stringifiedSites})
        AND r.category_level2 IS NOT NULL
`,
        params: {
            $fromDate: fromDate,
            $toDate: toDate,
            ...siteIdsParams,
        },
    };
};

const getReportsCount = (queryResults: QueryResult[]): number => {
    if (!queryResults?.length || !queryResults[0].values) {
        return 0;
    }

    return queryResults[0].values[0][0] ?? 0;
};

export default {
    top5: {
        query: getTop5Query,
        getResults: getTop5Results,
    },
    bySeverityLevel: {
        query: bySeverityLevelQuery,
        getResults: getBySeverityLevelResults,
    },
    reportsCount: {
        query: reportsCountQuery,
        getResults: getReportsCount,
    },
};
