import { Separator, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { formatDateTime } from "src/helpers/date";
import { DynamicReportType } from "src/models/ReportModel";
import { ISiteObject } from "src/models/SiteObjectModel";
import styled from "styled-components";

import { ViewersSummary } from "../ViewersSummary";
import { ReportedBy } from "./ReportedBy";

type Props = {
    siteObject: ISiteObject;
    report: DynamicReportType;
    isShared?: boolean;
};

const StackStyled = styled(Stack)`
    row-gap: ${Spacing.XS}px;
    flex-wrap: wrap;
`;

export const ReportDetailsHeaderLine1 = ({ report, siteObject, isShared }: Props) => {
    return (
        <StackStyled direction="row">
            <Text data-testid="detail-view-date-container" color="secondary" micro>
                {formatDateTime(report.reportDateTime)}
            </Text>
            <Separator variant="vertical" mh="S" />
            {!isShared && <ReportedBy reportedBy={report.reportedBy} countryCode={siteObject.countryCode} />}
            <ViewersSummary visitorIds={report.visitorIds} visitorsCount={report.visitorsCount} isShared={isShared} />
        </StackStyled>
    );
};
