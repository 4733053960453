import { Breakpoints, Icon, Separator, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { TFunction } from "i18next";
import { intersectionWith } from "lodash";
import React, { useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "src/components/shared/Modal/Modal";
import { sortByField } from "src/helpers/ArrayHelper";
import { formatDate } from "src/helpers/date";
import { useExport } from "src/hooks/ExportExcelHooks";
import { useOnEscapeEventListener } from "src/hooks/WebAccessibilityHooks";
import { ReportTemplateEnum, reportTypeTranslation, SiteZoneEnum } from "src/models/ReportModel";
import { useReportCategoryItems } from "src/store/filter/FilterHooks";
import FilterSelectors from "src/store/filter/FilterSelectors";
import { exportReportsToExcelSlice } from "src/store/reports/exportReportsToExcelSlice";
import { ExportStateEnum } from "src/store/reports/types";
import { RegionItem, useSiteObjectItemsByRegions } from "src/store/siteObjects/SiteObjectsHooks";

import { IMultiListItem } from "../shared/CheckboxList/CheckboxListItem.types";
import { InfoWrapper, StyledList } from "./ExcelExportModal.styled";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const getSelectedRegions = (items: RegionItem[], selectedItems: string[], t: TFunction) => {
    return items
        .filter((region) => selectedItems.includes(region.value))
        .map((region) => ({ header: region.label, locations: [t("excelExport.allLocations")], value: region.value }));
};

const getSelectedLocations = (country: RegionItem, selectedFilters: string[]): RegionItem[] =>
    country.children.filter((location) => selectedFilters.includes(location.value));

const getCountriesBySelectedLocations = (countries: RegionItem[], selectedItems: string[], t: TFunction) => {
    return countries
        .filter((country) => {
            const selectedLocations = getSelectedLocations(country, selectedItems);
            return !!selectedLocations.length;
        })
        .map((country) => {
            const selectedLocations = getSelectedLocations(country, selectedItems);
            const isAllSelected = selectedLocations.length === country.children.length;
            return {
                header: t(`country.${country.value}`),
                locations: isAllSelected ? [t("excelExport.allLocations")] : selectedLocations.map((location) => location.label),
            };
        });
};

const getChildren = (items: IMultiListItem[]): IMultiListItem[] =>
    items.reduce((acc, item) => {
        return acc.concat(item.children).concat(getChildren(item.children));
    }, []);

const addParentCategoryName = (categories: IMultiListItem[], controlCategories: string[]): IMultiListItem[] => {
    return categories.map((c) => {
        if (controlCategories.includes(c.value)) {
            return {
                ...c,
                label: `${c.parent.label} • ${c.label}`,
            };
        }
        return c;
    });
};

export const ExcelExportModal = ({ isOpen, onClose }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const preselectedReportTypes = useSelector(FilterSelectors.getSelectedReportTypes);
    const { sitesByRegions } = useSiteObjectItemsByRegions();
    const selectedRegions = useSelector(FilterSelectors.getSelectedRegions);
    const preselectedSiteLocations = useSelector(FilterSelectors.getSelectedSiteLocations);
    const preselectedStartDate = useSelector(FilterSelectors.getSelectedStartDate);
    const preselectedEndDate = useSelector(FilterSelectors.getSelectedEndDate);
    const locationsGroups = useMemo(() => {
        const allLocations = [{ header: t("filters.locations.label"), locations: [t("excelExport.allLocations")] }];
        const regions = getSelectedRegions(sitesByRegions.items, selectedRegions, t);
        if (regions.length === sitesByRegions.items.length) {
            return allLocations;
        }
        const countries = sitesByRegions.items
            .filter((region) => regions.findIndex((r) => r.value === region.value) === -1)
            .map((item) => item.children)
            .flat();

        const selectedCountries = getCountriesBySelectedLocations(countries, selectedRegions, t);

        if (selectedRegions.length) {
            return [...regions, ...selectedCountries];
        } else {
            return allLocations;
        }
    }, [sitesByRegions, selectedRegions, t]);

    const { reportCategories } = useReportCategoryItems();
    const selectedCategoryOneIds = useSelector(FilterSelectors.getSelectedCategoryOneIds);
    const selectedCategoryTwoIds = useSelector(FilterSelectors.getSelectedCategoryTwoIds);
    const selectedCategoryThreeIds = useSelector(FilterSelectors.getSelectedCategoryThreeIds);
    const selectedCategories = useMemo(() => {
        const flatCategories = getChildren(reportCategories).concat(reportCategories);
        const categories = intersectionWith(flatCategories, [...selectedCategoryOneIds, ...selectedCategoryTwoIds, ...selectedCategoryThreeIds], (a, b) => {
            return a.value === b;
        });
        const formattedCategories = addParentCategoryName(categories, selectedCategoryThreeIds);
        return formattedCategories.length
            ? formattedCategories.sort((a, b) => a.label.localeCompare(b.label))
            : [{ value: "", label: t("excelExport.allCategories") }];
    }, [selectedCategoryOneIds, selectedCategoryTwoIds, selectedCategoryThreeIds, reportCategories, t]);

    const selectedSiteZones = useMemo(() => {
        // Object.keys of enum returns keys and values so divide by 2
        if (preselectedSiteLocations.length === 0 || preselectedSiteLocations.length === Object.keys(SiteZoneEnum).length / 2) {
            return [t("excelExport.allZones")];
        }
        return preselectedSiteLocations.map((zone) => t(`siteLocations.${SiteZoneEnum[zone]}`));
    }, [preselectedSiteLocations, t]);

    const selectedReportTypes = useMemo(() => {
        if (preselectedReportTypes.length === 0 || Object.keys(ReportTemplateEnum).length === preselectedReportTypes.length) {
            return [t("excelExport.allTypes")];
        }
        return preselectedReportTypes.map((type) => (reportTypeTranslation[type] ? t(reportTypeTranslation[type]) : type));
    }, [preselectedReportTypes, t]);

    const { requestReports, exportState, totalCount } = useExport();
    const handleClose = useCallback(() => {
        onClose();
        dispatch(exportReportsToExcelSlice.actions.resetExportReports());
    }, [onClose, dispatch]);
    const isLoading = exportState === ExportStateEnum.loading;

    useOnEscapeEventListener(handleClose, null, isOpen);

    const modal = (
        <Modal
            title={t("excelExport.exportSummary")}
            size={"L"}
            overflow={"auto"}
            actions={[
                {
                    children: isLoading ? t("reports.export.loading") : t("common.export"),
                    onClick: requestReports,
                    loading: isLoading,
                },
            ]}
            onClose={handleClose}
            isOpen={isOpen}
        >
            <>
                <Text micro uppercase color="secondary">
                    {t("excelExport.reportsNumber")}
                </Text>
                <StyledList>
                    <li>
                        <Text>{totalCount}</Text>
                    </li>
                </StyledList>
                <Separator marginTop="S" marginBottom="S" />
                <Text micro uppercase color="secondary">
                    {t("excelExport.reportType")}
                </Text>
                <StyledList>
                    {selectedReportTypes.map((type) => (
                        <li key={type}>
                            <Text>{type}</Text>
                        </li>
                    ))}
                </StyledList>
                <Separator marginTop="S" marginBottom="S" />
                <Text micro uppercase color="secondary">
                    {t("excelExport.dates")}
                </Text>
                <Stack>
                    <Text bold>{t("common.from")}:</Text>
                    <Text>&nbsp;{formatDate(new Date(preselectedStartDate))}</Text>
                </Stack>

                <Stack>
                    <Text bold>{t("common.to")}:</Text>
                    <Text>&nbsp;{formatDate(new Date(preselectedEndDate))}</Text>
                </Stack>
                <Separator marginTop="S" marginBottom="S" />
                {[...locationsGroups].sort(sortByField("header")).map((group) => (
                    <React.Fragment key={group.header}>
                        <Text micro uppercase color="secondary">
                            {group.header}
                        </Text>
                        <StyledList>
                            {group.locations.map((location) => (
                                <li key={location}>
                                    <Text>{location}</Text>
                                </li>
                            ))}
                        </StyledList>
                    </React.Fragment>
                ))}
                <Separator marginTop="S" marginBottom="S" />
                <Text micro uppercase color="secondary">
                    {t("excelExport.category")}
                </Text>
                <StyledList>
                    {selectedCategories.map((c) => (
                        <li key={c.value}>
                            <Text>{c.label}</Text>
                        </li>
                    ))}
                </StyledList>
                <Separator marginTop="S" marginBottom="S" />
                <Text micro uppercase color="secondary">
                    {t("excelExport.siteZone")}
                </Text>
                <StyledList>
                    {selectedSiteZones.map((siteZone) => (
                        <li key={siteZone}>
                            <Text>{siteZone}</Text>
                        </li>
                    ))}
                </StyledList>
                <InfoWrapper>
                    <Icon variant="Info" color="tertiary" />
                    <Text micro color="tertiary">
                        {t("excelExport.tipFromUs")}
                    </Text>
                </InfoWrapper>
            </>
        </Modal>
    );

    if (isMobile) {
        return ReactDOM.createPortal(modal, document.body);
    }
    return modal;
};
