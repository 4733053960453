/**
 *
 * @param categoryIncidentCount number of incidents in specific category
 * @param totalIncidentCount number of all incidents
 * @returns
 */
import { Palette } from "@secuis/ccp-react-components";

export const getCellColor = (categoryIncidentCount: number, totalIncidentCount: number): (typeof Palette)[keyof typeof Palette] => {
    const percentage = totalIncidentCount === 0 ? 0 : (categoryIncidentCount / totalIncidentCount) * 100;

    if (percentage === 0) {
        return Palette.Purple100;
    } else if (percentage < 25) {
        return Palette.Purple200;
    } else if (percentage < 50) {
        return Palette.Purple300;
    } else if (percentage < 75) {
        return Palette.Purple400;
    } else if (percentage < 100) {
        return Palette.Purple500;
    } else if (percentage === 100) {
        return Palette.Purple600;
    }
};
