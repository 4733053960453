import { Box, Palette, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

type SitesLegendProps = {
    sites: {
        index: string;
        name: string;
    }[];
};

const SitesLegendStyled = styled(Box)`
    position: relative;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: ${Palette.Navy300};
        opacity: 0.3;
    }
`;

export const SitesLegend = ({ sites }: SitesLegendProps) => {
    return (
        <SitesLegendStyled mt="L" pb="S" data-testid="matrix-sites-legend">
            <Box mb="S">
                <Text bold>Sites</Text>
            </Box>
            <Stack justifyContent="flex-start" flexWrap="wrap">
                {sites.map((site) => {
                    return (
                        <Stack key={site.index} mr="S" mb="S" data-testid="matrix-sites-legend-item">
                            <Box mr="S">
                                <Text color="tertiary" data-testid="matrix-sites-legend-item-index">
                                    {site.index}
                                </Text>
                            </Box>
                            <Text data-testid="matrix-sites-legend-item-name">{site.name}</Text>
                        </Stack>
                    );
                })}
            </Stack>
        </SitesLegendStyled>
    );
};
