import { Breakpoints, Stack, useDebounce, useHasMaxWidth } from "@secuis/ccp-react-components";
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportsSlice } from "src/store/reports/reportsSlice";

import { useInAppGuide } from "../../../../components/AppGuide/AppGuideHooks";
import { ReportDetailsSteps } from "../../../../components/AppGuide/AppGuideSteps";
import Comments from "../../../../components/Reports/Comments/Comments";
import { getConfig, getConfigPatrolTour } from "../../../../components/Reports/reportConfigs/DynamicTemplateConfig";
import { DetailsNavbar } from "../../../../components/Reports/SharedComponents/DetailsNavbar";
import { Login } from "../../../../components/Reports/SharedComponents/Login";
import { Loading } from "../../../../components/shared/Loading";
import { useIsInViewport } from "../../../../hooks/ViewportHooks";
import { DynamicComponentConfigType } from "../../../../models/DynamicTemplate";
import { DynamicReportType, isPatrolTour, PatrolTourType } from "../../../../models/ReportModel";
import { ISiteObject } from "../../../../models/SiteObjectModel";
import { useTrackReportVisit } from "../../../../store/insights/CardsHooks";
import ReportsSelectors from "../../../../store/reports/ReportsSelectors";
import { CommentReportDetailsContainer, LoadingContainer, MainReportDetailsContainer, SplitContainer, VerticalReportDetailsLine } from "./Generic.styles";
import { ReportDetailsMain } from "./ReportDetailsMain";

type Props = {
    report: DynamicReportType | PatrolTourType;
    siteObject: ISiteObject;
    isReportListDetails?: boolean;
    onClose?: () => void;
    sharedReportId?: string;
    showBackButton?: boolean;
};

const FloatingLoading = () => (
    <LoadingContainer>
        <Loading />
    </LoadingContainer>
);

const Generic = ({ report, siteObject, onClose, sharedReportId, isReportListDetails, showBackButton = false }: Props) => {
    const reportUrl = `global/reports/details/${report?.locationId}/${report?.id}`;
    const isTabletOrMobile = useHasMaxWidth(Breakpoints.M);
    const containerRef = useRef<HTMLDivElement>(null);
    const isSharedReportDesktop = !isTabletOrMobile && !!sharedReportId;
    const isSinglePageReport = !isTabletOrMobile && !sharedReportId && !isReportListDetails;
    const isFullScreenMobileView = (!sharedReportId && isTabletOrMobile) || isReportListDetails;
    const showVerticalLine = !isReportListDetails && !isTabletOrMobile;
    const [isStickyHeader, setIsStickyHeader] = useState(false);
    const [configChecked, setConfigChecked] = useState(false);
    const debouncedStickyHeader = useDebounce(isStickyHeader, 60);
    const { startAppGuide } = useInAppGuide();
    useTrackReportVisit(report);

    const [areTranslationsLoading, setAreTranslationsLoading] = useState(true);

    const commentsRef = useRef(null);
    const isInViewport = useIsInViewport(commentsRef);

    const scrollEvent = useCallback((e: SyntheticEvent) => {
        const target = e.target as HTMLTextAreaElement;
        setIsStickyHeader(target.scrollTop > 0);
    }, []);

    useEffect(() => {
        if (isInViewport) startAppGuide(ReportDetailsSteps(), "commentsSection");
    }, [isInViewport, startAppGuide]);

    const areDynamicTranslationsLoading = useSelector(ReportsSelectors.areDynamicTranslationsLoading);
    const translationLoadingPending = useSelector((state) => ReportsSelectors.getTranslationLoadingPending(state, report.id));

    const dispatch = useDispatch();

    const config = useMemo(() => {
        if (!report || !siteObject) return [];
        return isPatrolTour(report) ? getConfigPatrolTour({ report, siteObject }) : getConfig({ report, siteObject });
    }, [report, siteObject]);

    const checkForTranslation = useMemo(() => {
        return (config: DynamicComponentConfigType[]) => {
            return config.some((item) => {
                const componentProps = item.props ?? null;
                if (componentProps?.shouldAutoTranslate) return true;
                if (item.children?.length) return checkForTranslation(item.children);

                return false;
            });
        };
    }, []);

    useEffect(() => {
        dispatch(reportsSlice.actions.setReportTranslationPending({ id: report.id, translationLoadingPending: checkForTranslation(config) }));
        setConfigChecked(true);
    }, [report, checkForTranslation, config, dispatch]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const isAnyTranslationLoading = areDynamicTranslationsLoading && translationLoadingPending;
            if (isAnyTranslationLoading !== areTranslationsLoading) setAreTranslationsLoading(isAnyTranslationLoading);
        }, 200);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [areDynamicTranslationsLoading, translationLoadingPending, areTranslationsLoading]);

    return (
        <MainReportDetailsContainer key="report-scrollable-container" ref={containerRef} onScroll={scrollEvent}>
            {(!sharedReportId || isTabletOrMobile) && (
                <DetailsNavbar
                    isStickyHeader={debouncedStickyHeader}
                    report={report}
                    onClose={onClose}
                    sharedReportId={sharedReportId}
                    showCloseButton={isReportListDetails || isFullScreenMobileView}
                    showBackButton={showBackButton}
                    siteObject={siteObject}
                />
            )}
            {areTranslationsLoading || !configChecked ? (
                <FloatingLoading />
            ) : (
                <>
                    <SplitContainer>
                        {<ReportDetailsMain report={report} siteObject={siteObject} sharedReportId={sharedReportId} />}
                        {showVerticalLine && <VerticalReportDetailsLine />}
                        {isSharedReportDesktop && (
                            <Stack direction="column" flex={1}>
                                <DetailsNavbar
                                    isStickyHeader={isStickyHeader}
                                    report={report}
                                    onClose={onClose}
                                    sharedReportId={sharedReportId}
                                    showCloseButton={isReportListDetails}
                                    isSharedReportDesktop={isSharedReportDesktop}
                                    siteObject={siteObject}
                                />
                                <Login report={report} siteObject={siteObject} />
                            </Stack>
                        )}
                        {isSinglePageReport && report && (
                            <CommentReportDetailsContainer>
                                <Comments report={report} reportUrl={reportUrl} isSinglePageReport={isSinglePageReport} />
                            </CommentReportDetailsContainer>
                        )}
                    </SplitContainer>
                    {isFullScreenMobileView && <Comments report={report} reportUrl={reportUrl} ref={commentsRef} />}
                </>
            )}
        </MainReportDetailsContainer>
    );
};

export default Generic;
