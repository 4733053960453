import { DateTimeVariantsMap, MonthWidth } from "./types";

export const DATE_TIME_VARIANTS_MAP: DateTimeVariantsMap = {
    longDateTime: "PPPp", // dd MMMM yyyy p
    midDateTime: "PPp", // dd MMM yyyy p
    shortDateTime: "P p", // dd MM yyyy p
    dayMonthDateTime: "d MMM p",
    dayMonthDate: "d MMMM",
    shortDayMonthDate: "d MMM",
    longDate: "PPP", // dd MMMM yyyy
    midDate: "PP", // dd MMM yyyy
    shortDate: "P", // dd MM yyyy
    shortTime: "p",
};

export const isoDateFormat = "yyyy-MM-dd";

export const DATE_FNS_MONTH_WIDTH_MAP: Readonly<Record<MonthWidth, string>> = {
    short: "narrow",
    mid: "abbreviated",
    full: "wide",
};
