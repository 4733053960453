import { Breakpoints, Palette } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { getCellColor } from "../../../helpers";
import { CellStyled } from "../../Matrix.styles";

type DataCellStyledProps = {
    categoryIncidentCount: number;
    totalIncidentCount: number;
};

export const DataCellStyled = styled(CellStyled)<DataCellStyledProps>`
    background: ${(props) => getCellColor(props.categoryIncidentCount, props.totalIncidentCount)};
    color: ${(props) => (props.categoryIncidentCount === props.totalIncidentCount && props.totalIncidentCount !== 0 ? Palette.Gray100 : Palette.Navy800)};

    @media (max-width: ${Breakpoints.XS}) {
        min-width: 100px;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        z-index: 1;
        pointer-events: none;
        transition:
            background 0.1s,
            outline 0.1s,
            box-shadow 0.1s;
    }
`;
