import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect, useState } from "react";

type PaginationProps<T> = {
    items: T[];
    itemsPerPage: number;
};

export const usePagination = <T>({ items, itemsPerPage }: PaginationProps<T>) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const [currentPage, setCurrentCategoryPage] = useState(1);
    const [currentItems, setCurrentItems] = useState<T[]>([]);

    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = isMobile ? start + itemsPerPage + 1 : start + itemsPerPage;

        setCurrentItems(items.slice(start, end));
    }, [currentPage, items, itemsPerPage, isMobile]);

    const showPreviousPage = () => {
        setCurrentCategoryPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const showNextPage = () => {
        setCurrentCategoryPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    return {
        totalPages,
        currentItems,
        currentPage,
        showPreviousPage,
        showNextPage,
    };
};
