export const featureFlags = {
    assignmentReports: "experiment-assignment-reports",
    calloutReports: "experiment-call-out-reports",
    vehicleInspectionReports: "release-vehicle-inspection-reports",
    autotranslations: "experiment-autotranslations",
    inMemoryDemoClient: "entitlement-in-memory-demo-client",
    viewSchedule: "release-view-schedule",
    kpiSummary: "release-kpi-summary",
    incidentMatrix: "release-incident-matrix",
};
