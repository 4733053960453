import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IMultiListItem } from "../../components/shared/CheckboxList/CheckboxListItem.types";
import { useFilterableReportTypes } from "../../hooks/ReportTypeHooks";
import { Databases, QueryResult } from "../../models/DbModel";
import { damagedCategories, GetDamagedReportsCategories } from "../../sql/scripts/insights/damageQueries";
import { IState } from "../../Store";
import { isFinished, RequestStatus } from "../RequestStatus";
import { useFilteredSites } from "./FilterHooks";
import InsightsActions from "./InsightsActions";
import { useDatabase } from "./InsightsLoadHooks";
import { DamageReportType } from "./InsightsModel";
import InsightsSelectors from "./InsightsSelectors";
import { generateQueryKey } from "./keys";
import { IDamageReportsState } from "./types";

type TopDamageCategoryType = {
    count: number;
    label: string;
    category: string[];
};

export const getTopDamagesCategories = (
    reports: { [k: string]: number },
    categoriesList: IMultiListItem[],
    otherLabel: string,
    topIndex = 5,
): TopDamageCategoryType[] => {
    const allLevel2Categories = categoriesList.map((c) => c.children).flat();
    const getLabel = (category: string) => allLevel2Categories.find((c) => c.value === category)?.label;
    const formatDamageReports = (report: [string, number]) => {
        const [category, count] = report;
        return {
            label: getLabel(category),
            category: [category],
            count,
        };
    };
    const reportsList = Object.entries(reports).sort((a, b) => {
        const [, countA] = a;
        const [, countB] = b;
        return countB - countA;
    });
    if (reportsList.length <= topIndex) {
        return reportsList.slice(0, topIndex).map(formatDamageReports);
    } else {
        const otherDamages = reportsList.slice(topIndex - 1).reduce((acc, report) => {
            const [category, count] = report;
            const { category: accCategory = [], count: accCount = 0 } = acc;

            return {
                label: otherLabel,
                category: [...accCategory, category],
                count: accCount + count,
            };
        }, {} as TopDamageCategoryType);
        return reportsList
            .slice(0, topIndex - 1)
            .map(formatDamageReports)
            .concat(otherDamages);
    }
};

export const getDamagesCount = (reports: { [k: string]: number }): number => Object.values(reports).reduce((acc, val) => acc + val, 0);

const formatDamageReportsToState = (damageReports: QueryResult[]): DamageReportType => {
    if (damageReports?.length) {
        const { values } = damageReports[0];

        return values.reduce((acc, value) => {
            const [siteId, category, damageCount] = value;

            return {
                ...acc,
                [siteId]: {
                    ...acc[siteId],
                    [category]: damageCount,
                },
            };
        }, {});
    }

    return {};
};

const getCategoriesLevel3 = (categories2: string[]) =>
    damagedCategories
        .filter((category) => categories2.includes(category.categoryLevel2))
        .map((category) => category.categoriesLevel3)
        .flat();

export const useDamageReports = (): {
    isLoading: boolean;
    damageReports: DamageReportType;
} => {
    const dispatch = useDispatch();
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const { siteIds } = useFilteredSites();
    const { queryStatus, damageReports, key } = useSelector<IState, IDamageReportsState>(InsightsSelectors.getDamageReports);

    const isLoading = useMemo(() => {
        return !dbReadyToQuery || !isFinished(queryStatus);
    }, [dbReadyToQuery, queryStatus]);

    const getDamageReports = useCallback(async () => {
        const reportByDamageQuery = GetDamagedReportsCategories("damaged_reports_categories", siteIds, selectedStartDate, selectedEndDate);
        const reportByDamageJob = execute(reportByDamageQuery);
        const damages = await Promise.all([reportByDamageJob]);
        return formatDamageReportsToState(damages[0]?.results);
    }, [execute, siteIds, selectedStartDate, selectedEndDate]);

    useEffect(() => {
        const getDamages = async (queryKey: string) => {
            dispatch(InsightsActions.requestDamageReports(queryKey));
            const damageReports = await getDamageReports();
            dispatch(InsightsActions.damageReportsSuccess(damageReports, queryKey));
        };
        const newKey = generateQueryKey({ locationIds: siteIds, fromDate: selectedStartDate, toDate: selectedEndDate });
        if (!dbReadyToQuery || (key === newKey && (queryStatus === RequestStatus.loading || isFinished(queryStatus)))) {
            return;
        }
        getDamages(newKey);
    }, [dbReadyToQuery, queryStatus, dispatch, getDamageReports, siteIds, key, selectedStartDate, selectedEndDate]);

    return { isLoading, damageReports };
};

export const useDamageReportsRedirection = () => {
    const navigate = useNavigate();
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const selectedRegions = useSelector(InsightsSelectors.getSelectedRegions);
    const { defaultInsightsReportTypes } = useFilterableReportTypes();

    const categoriesLevel3 = damagedCategories.map((category) => category.categoriesLevel3).flat();
    const categoryStr = categoriesLevel3.toString();

    const redirectToAllDamagedReports = useCallback(() => {
        navigate(
            `/reports?selectedCategoryThree=${categoryStr}&selectedRegions=${selectedRegions.toString()}&selectedStartDate=${selectedStartDate.toISOString()}&selectedEndDate=${selectedEndDate.toISOString()}&selectedReportTypes=${defaultInsightsReportTypes.toString()}`,
        );
    }, [selectedRegions, selectedStartDate, selectedEndDate, navigate, categoryStr, defaultInsightsReportTypes]);

    const redirectToLocationDamageReports = useCallback(
        (siteId: string) => {
            navigate(
                `/reports?selectedCategoryThree=${categoryStr}&selectedRegions=${siteId}&selectedStartDate=${selectedStartDate.toISOString()}&selectedEndDate=${selectedEndDate.toISOString()}&selectedReportTypes=${defaultInsightsReportTypes.toString()}`,
            );
        },
        [selectedStartDate, selectedEndDate, navigate, categoryStr, defaultInsightsReportTypes],
    );

    const redirectToCategory2Location = useCallback(
        (siteId: string, categoryLevel2: string[]) => {
            const categoryStr = getCategoriesLevel3(categoryLevel2).toString();
            navigate(
                `/reports?selectedCategoryThree=${categoryStr}&selectedRegions=${siteId}&selectedStartDate=${selectedStartDate.toISOString()}&selectedEndDate=${selectedEndDate.toISOString()}&selectedReportTypes=${defaultInsightsReportTypes.toString()}`,
            );
        },
        [selectedStartDate, selectedEndDate, navigate, defaultInsightsReportTypes],
    );

    return { redirectToAllDamagedReports, redirectToLocationDamageReports, redirectToCategory2Location };
};
