import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "src/helpers/date";
import { PatrolTourType } from "src/models/ReportModel";
import { IUser } from "src/models/UserModel";

import { styles } from "../pdfStyles";
import { TimeIconPdf } from "./Icons";
import { PdfViewers } from "./PdfViewers";

type Props = {
    report: PatrolTourType;
    isShared: boolean;
    user: IUser;
};

export const PdfPatrolDetailsHeaderLine1 = ({ report, isShared, user }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <View style={styles.sectionLineBreak}></View>
            <View style={styles.detailsLine}>
                <View style={styles.detailsWrappableWrapper}>
                    <View>
                        <Text style={styles.headerDate}>{formatDateTime(report.endDateTime)}</Text>
                    </View>
                    <View style={styles.verticalDivider} />
                    <View style={styles.detailsIconContainer}>
                        <TimeIconPdf />
                        <Text style={styles.locationText}>{t("common.minutes", { count: report.durationMinutes ?? 0 })}</Text>
                    </View>
                    <View style={styles.verticalDivider} />
                    <PdfViewers report={report} isShared={isShared} user={user} />
                </View>
            </View>
        </>
    );
};
