import { Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { getLettersFromNumber } from "src/helpers/graphs";

import { SiteCellStyled, SiteIconStyled, TextSiteNameStyled, TextTotalStyled } from "./SiteCell.styles";

export type SiteCellProps = {
    index: number;
    name: string;
    total: number;
};

export const SiteCell = ({ index, name, total }: SiteCellProps) => {
    const { t } = useTranslation();
    const isNotLargeScreen = useHasMaxWidth(Breakpoints.L);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const alphabeticalIndex = getLettersFromNumber(index);

    return (
        <SiteCellStyled className="siteCell">
            <Stack alignItems="center" justifyContent="space-between" direction={isNotLargeScreen ? "column" : "row"}>
                <Stack alignItems="center">
                    <SiteIconStyled color="white" size="M" variant="BuildingFilled" />
                    <TextSiteNameStyled data-site-name={name} data-site-index={alphabeticalIndex} truncate data-testid="matrix-table-site-cell-name">
                        {isMobile ? alphabeticalIndex : name}
                    </TextSiteNameStyled>
                </Stack>
                <TextTotalStyled color="secondary" small data-testid="matrix-table-site-cell-total">
                    {t("incidentMatrix.table.total")}: {total}
                </TextTotalStyled>
            </Stack>
        </SiteCellStyled>
    );
};
