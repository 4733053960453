import { Box, Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import { TableComponent } from "src/components/shared/Table/Table";
import styled from "styled-components";

export const MatrixStyled = styled(Box)`
    background: ${Palette.Navy750};
    margin-bottom: ${Spacing.M}px;
    position: relative;
`;

const MAX_COLUMN_WIDTH = 285;

export type TableStyledProps = {
    colMaxWidthEnabled: boolean;
    scrollable: boolean;
    categoryColumnCount: number;
};

export const TableStyled = styled(TableComponent)<TableStyledProps>`
    overflow-x: hidden;
    position: relative;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${Palette.Navy300};
        opacity: 0.3;
    }

    ${({ colMaxWidthEnabled }) =>
        colMaxWidthEnabled &&
        `@media (min-width: ${Breakpoints.L}) {
            max-width: ${MAX_COLUMN_WIDTH * 4}px;
        }`}

    ${({ scrollable }) =>
        scrollable &&
        `div[role="rowgroup"]:nth-child(2) {
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 640px;

            @media (max-width: ${Breakpoints.L}) {
                max-height: 620px;
            }

            @media (max-width: ${Breakpoints.XS}) {
                max-height: 880px;
            }
        }`};

    div[role="rowgroup"] {
        position: relative;

        grid-template-columns: 340px ${({ categoryColumnCount }) => `repeat(${categoryColumnCount}, 1fr)`};

        @media (max-width: ${Breakpoints.XL}) {
            grid-template-columns: 290px ${({ categoryColumnCount }) => `repeat(${categoryColumnCount}, 1fr)`};
        }

        @media (max-width: ${Breakpoints.M}) {
            grid-template-columns: 188px ${({ categoryColumnCount }) => `repeat(${categoryColumnCount}, 1fr)`};
        }

        @media (max-width: ${Breakpoints.XS}) {
            grid-template-columns: 70px ${({ categoryColumnCount }) => `repeat(${categoryColumnCount}, auto)`};
        }

        @media (max-width: 580px) {
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 19px;
                height: 100%;
                background: linear-gradient(to right, rgba(0, 0, 0, 0), ${Palette.Navy750} 100%);
                opacity: 0.6;
            }
        }

        &:first-child {
            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: ${Palette.Navy300};
                opacity: 0.3;
            }
        }

        &:nth-child(2) > div[role="row"]:first-child button::before {
            content: none;
        }
    }

    div[role="columnheader"] {
        padding-top: ${Spacing.XS}px;
    }

    div[role="row"],
    div[role="cell"] {
        padding: 0;
    }

    div[role="row"] {
        background: transparent;
        border: none;

        @media (max-width: ${Breakpoints.XS}) {
            overflow: hidden;
        }
    }
`;

export const CellStyled = styled.button`
    width: 100%;
    height: 100%;
    text-align: left;
    position: relative;
    padding: 20px ${Spacing.S}px;

    @media (max-width: ${Breakpoints.M}) {
        padding: 20px ${Spacing.S}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.L}px ${Spacing.S}px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${Palette.Navy300};
        opacity: 0.3;
    }
`;
