import { format } from "date-fns";

import { generateStringForQuery } from "../queryUtilities";

export const getIncidentsLocationsCategories = (siteIds: string[], start: Date, end: Date, categories: string[], reportTypes: string[]): string => {
    const localStartDate = format(start, "yyyy-MM-dd HH:mm:ss.SSS");
    const localEndDate = format(end, "yyyy-MM-dd HH:mm:ss.SSS");

    return `SELECT root_site_location, COUNT(root_site_location)
    FROM reports
    WHERE  report_date_time >= '${localStartDate}'
    AND report_date_time <= '${localEndDate}'
    AND location_id IN (${generateStringForQuery(siteIds)})
    AND template in (${generateStringForQuery(reportTypes)})
    AND category_level1 IN (${generateStringForQuery(categories)})
    GROUP BY root_site_location;`;
};
