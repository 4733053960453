import { useSelector } from "react-redux";
import { useSqlQuery } from "src/sql/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";
import InsightsSelectors from "src/store/insights/InsightsSelectors";
import LocationsSelectors from "src/store/locations/LocationsSelectors";

import { getMatrixDataQuery, parseMatrixDataQueryResult } from "../Matrix.queries";
import { DataItem } from "../Matrix.types";

type useMatrixDataResult = {
    data: DataItem[];
    totalIncidentCount: number;
    isLoading: boolean;
};

export const useMatrixData = (categories: string[]): useMatrixDataResult => {
    const { queryResult, isLoading } = useMatrixDataQuery(categories);
    const { siteObjects } = useSelector(LocationsSelectors.getAuthorizedLocations);
    const siteNames = new Map(siteObjects.map((site) => [site.id, site.name]));

    let data: DataItem[] = [];
    let totalIncidentCount = 0;

    if (!isLoading && queryResult) {
        // sort data by total site incidents
        queryResult.sort((a, b) => b.site.total - a.site.total);

        data = queryResult.map((item) => {
            totalIncidentCount += item.site.total;
            const siteName = siteNames.get(item.site.id);

            return {
                ...item,
                site: {
                    total: item.site.total,
                    name: siteName,
                },
            } as DataItem;
        });
    }

    return {
        totalIncidentCount,
        data,
        isLoading,
    };
};

const useMatrixDataQuery = (categories: string[]) => {
    const { siteIds } = useFilteredSites();
    const startDate = useSelector(InsightsSelectors.getSelectedStartDate).toISOString();
    const endDate = useSelector(InsightsSelectors.getSelectedEndDate).toISOString();
    const queryParams = { siteIds, startDate, endDate, categories };

    const { isLoading, queryResult } = useSqlQuery(getMatrixDataQuery, parseMatrixDataQueryResult, queryParams);

    return { queryResult, isLoading };
};
