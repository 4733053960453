import { Breakpoints, Stack, Text, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { ExcelExportButton } from "../../../../components/ExcelExport/ExcelExportButton";
import { ReportsFilter } from "../../../../components/Reports/ReportsFilter";
import { FilterIcon } from "../../../../components/shared/filters/FilterIcon/FilterIcon";
import { ExpandablePanel } from "../../../../components/shared/filters/FilterToolbar.styles";
import { FilterSummaryChips } from "./FiltersSummaryChips";
import { ReportsDateFilter } from "./ReportsDateFilter";
import { StackStyled } from "./SearchAndFilter.styled";

type PropsType = {
    activeFiltersCount: number;
    onFilterClick: () => void;
    closeAllHandler: () => void;
    showFilterToolbar: boolean;
    reportsCount?: number;
    isLoading: boolean;
};

export const SearchAndFilter = ({ reportsCount, isLoading, activeFiltersCount, onFilterClick, showFilterToolbar, closeAllHandler }: PropsType) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return (
        <Stack direction="column">
            <StackStyled justifyContent="space-between" direction={"column"}>
                <Stack justifyContent="space-between" mb={isMobile ? "XS" : null} flexWrap="wrap">
                    <Title data-testid="reportlist-title">{t("reportlist.title")}</Title>
                    {!isMobile ? (
                        <ReportsDateFilter onCalendarOpen={closeAllHandler} />
                    ) : (
                        <FilterIcon activeFiltersCount={activeFiltersCount} onClick={onFilterClick} />
                    )}
                </Stack>
                {isMobile && <ReportsDateFilter />}
                <FilterSummaryChips />
                <Stack data-testid="navbar-filters-section" flex="1 1 auto" alignItems="center" justifyContent="flex-start" mt={"S"}>
                    {!isLoading && !isNaN(reportsCount) && (
                        <Text small bold data-testid="reports-count" color="tertiary">
                            {reportsCount} {reportsCount !== 1 ? t("reportlist.subtitle.reports") : t("reportlist.subtitle.report")}
                        </Text>
                    )}
                    <ExcelExportButton />
                </Stack>
            </StackStyled>

            {isMobile && showFilterToolbar && (
                <ExpandablePanel>
                    <ReportsFilter onClose={() => onFilterClick()} />
                </ExpandablePanel>
            )}
        </Stack>
    );
};
