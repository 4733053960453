import { QueryTimeRange } from "../../../sql/scripts/insights/highlightsQueries";

interface CardSetup {
    dbQueryDateRange: QueryTimeRange;
    urlStartDate: string;
    urlEndDate: string;
}

interface UrlTimeRange {
    minusDays?: number;
    minusMonths?: number;
}

interface ISummaryCardsConfiguration {
    accessControlCategories: CardSetup;
    accessControlSystemsCategories: CardSetup;
    medicalAssistanceCategories: CardSetup;
    confrontationCategories: CardSetup;
    facilityInspectionCategories: CardSetup;
    equipmentAccidentCategories: CardSetup;
    reportedAuditsCategories: CardSetup;
}

const makeTwoDigit = (value: number) => {
    return value.toString().padStart(2, "0");
};

const GetPastDate = (timeRange: UrlTimeRange): string => {
    const date = new Date();
    if (timeRange.minusDays) date.setDate(date.getDate() - timeRange.minusDays);

    return `${date.getFullYear()}-${makeTwoDigit(date.getMonth() + 1)}-${makeTwoDigit(date.getDate())}`;
};

const UrlCurrentDate = new Date().toISOString();
const UrlLastWeek: UrlTimeRange = { minusDays: 6 };
const UrlLast2Weeks: UrlTimeRange = { minusDays: 13 };
const UrlLast30Days: UrlTimeRange = { minusDays: 29 };
const UrlLast3Months: UrlTimeRange = { minusDays: 89 };

export const SummaryCardsConfiguration: ISummaryCardsConfiguration = {
    accessControlCategories: { dbQueryDateRange: QueryTimeRange.Last3Months, urlEndDate: UrlCurrentDate, urlStartDate: GetPastDate(UrlLast3Months) },
    accessControlSystemsCategories: { dbQueryDateRange: QueryTimeRange.LastWeek, urlEndDate: UrlCurrentDate, urlStartDate: GetPastDate(UrlLastWeek) },
    medicalAssistanceCategories: {
        dbQueryDateRange: QueryTimeRange.Last3Months,
        urlEndDate: UrlCurrentDate,
        urlStartDate: GetPastDate(UrlLast3Months),
    },
    confrontationCategories: { dbQueryDateRange: QueryTimeRange.Last3Months, urlEndDate: UrlCurrentDate, urlStartDate: GetPastDate(UrlLast3Months) },
    facilityInspectionCategories: {
        dbQueryDateRange: QueryTimeRange.Last2Weeks,
        urlEndDate: UrlCurrentDate,
        urlStartDate: GetPastDate(UrlLast2Weeks),
    },
    equipmentAccidentCategories: {
        dbQueryDateRange: QueryTimeRange.Last3Months,
        urlEndDate: UrlCurrentDate,
        urlStartDate: GetPastDate(UrlLast3Months),
    },
    reportedAuditsCategories: { dbQueryDateRange: QueryTimeRange.Last30Days, urlEndDate: UrlCurrentDate, urlStartDate: GetPastDate(UrlLast30Days) },
};
