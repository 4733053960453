import { Breakpoints, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const ListItem = styled(Stack)<{ active: boolean }>`
    gap: ${Spacing.XS}px;
    position: relative;
    cursor: pointer;
    border: 2px solid ${({ active }) => (active ? Palette.Purple300 : "transparent")};
    background-color: ${Palette.Navy750};
    margin-bottom: ${Spacing.XS}px;
    padding: ${Spacing.S}px ${Spacing.L}px ${Spacing.S}px ${Spacing.L}px;

    &:active {
        background-color: ${Palette.Navy850};
    }
    @media (min-width: ${Breakpoints.M}) {
        &:hover {
            background-color: ${Palette.Navy800};
            border: 2px solid ${({ active }) => (active ? Palette.Purple400 : "transparent")};
        }
    }

    @media (min-width: ${Breakpoints.S}) and (max-width: ${Breakpoints.M}) {
        margin-bottom: ${Spacing.XS}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding-right: ${Spacing.M}px;
    }
`;

export const ColumnStack = styled(Stack)`
    flex: 1;
    flex-direction: column;
    gap: ${Spacing.XS}px;
`;

export const ReportNames = styled.div`
    position: relative;
`;

export const TemplateStack = styled(Stack)`
    column-gap: ${Spacing.XS}px;
    row-gap: 0;
`;

export const UnreadDotWrapper = styled.div`
    padding: ${Spacing.XS}px 6px;
    position: absolute;
    left: -${Spacing.M}px;
`;

export const ItemDetails = styled.div`
    align-items: center;
`;

export const WrapabbleFlex = styled(Stack)`
    flex-wrap: wrap;

    > ${Text} {
        display: inline-block;
        white-space: pre-wrap;
    }
`;
export const SiteNameText = styled(Text)`
    @media (max-width: ${Breakpoints.XS}) {
        text-align: left;
    }
`;

export const ReportIdText = styled(Text)`
    text-align: right;
    word-break: break-all;
`;
