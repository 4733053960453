export const getParameterizedArray = (array: string[]) => {
    return array.reduce((keys, siteId, i) => {
        keys[`$k${i}`] = siteId;
        return keys;
    }, {});
};

export const getSqlStringifiedArrayFromObject = (obj: Record<string, unknown>): string => {
    return Object.keys(obj)
        .map((k) => k)
        .join(",");
};

export const generateStringForQuery = (siteIds: string[]): string => {
    return siteIds
        .reduce((siteIdsString, siteId) => {
            siteIdsString += `"${siteId}",`;
            return siteIdsString;
        }, "")
        .slice(0, -1);
};
