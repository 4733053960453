import { joinedSorted } from "./../../helpers/ArrayHelper";

export interface IReportSearchKey {
    locationIds: string[];
    reportCategories1: string[];
    reportCategories2: string[];
    reportCategories3: string[];
    reportTypes: string[];
    siteLocations: string[];
    severityLevels: string[];
    fromDateTime: Date;
    toDateTime: Date;
    showOnlyUnread?: boolean;
}

export const makeReportSearchKey = (keyParams: IReportSearchKey): string => {
    return `${joinedSorted(keyParams.locationIds || [])}
    _${joinedSorted(keyParams.reportCategories1 || [])}
    _${joinedSorted(keyParams.reportCategories2 || [])}
    _${joinedSorted(keyParams.reportCategories3 || [])}
    _${joinedSorted(keyParams.reportTypes || [])}
    _${joinedSorted(keyParams.siteLocations || [])}
    _${joinedSorted(keyParams.severityLevels || [])}
    _${keyParams.fromDateTime?.getTime()}
    _${keyParams.toDateTime?.getTime()}
    _${!!keyParams.showOnlyUnread}`;
};
