import { Breakpoints, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useResizeObserver } from "../../../hooks/CommonHooks";
import { getDamagesCount, useDamageReportsRedirection } from "../../../store/insights/DamageReportsHooks";
import { useFilteredSites } from "../../../store/insights/FilterHooks";
import { DamageReportType } from "../../../store/insights/InsightsModel";
import { useAuthorizedLocations } from "../../../store/locations/LocationsHooks";
import {
    Dot,
    ShowMoreWrapper,
    SiteCardsContainer,
    StyledButton,
    StyledIcon,
    StyledSection,
    StyledTile,
    StyledTileHeader,
    TileWrapper,
} from "./ReportedDamagesTile.styled";
import { EmptySiteCard, SiteCard } from "./SiteCard";

const WRAPPER_HEIGHT_DESKTOP = 468;
const WRAPPER_HEIGHT_MOBILE = 705;

type PropsType = {
    damageReports: DamageReportType;
};

export const ReportedDamagesTile = ({ damageReports }: PropsType) => {
    const { t } = useTranslation();
    const { redirectToAllDamagedReports } = useDamageReportsRedirection();
    const { siteIds: selectedSiteIds } = useFilteredSites();
    const { siteObjects } = useAuthorizedLocations();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [hasShowMore, setHasShowMore] = useState(true);
    const wrapperRef = useRef<HTMLDivElement>();

    const damageReportsList = Object.entries(damageReports).sort((a, b) => {
        const [, damagesA] = a;
        const [, damagesB] = b;
        return getDamagesCount(damagesB) - getDamagesCount(damagesA);
    });

    const hasDamages = damageReportsList.length;
    const locationsWithoutDamage = selectedSiteIds.filter((id) => !damageReports[id]);
    const maxHeight = isMobile ? WRAPPER_HEIGHT_MOBILE : WRAPPER_HEIGHT_DESKTOP;

    const [, height] = useResizeObserver(wrapperRef?.current);

    useLayoutEffect(() => {
        // wait for wrapper to recalculate height
        setTimeout(() => {
            const showMore = height > maxHeight;
            setIsCollapsed(showMore);
            setHasShowMore(showMore);
        }, 0);
    }, [maxHeight, height]);

    const toggleCollapsed = () => {
        setIsCollapsed(!isCollapsed);
    };

    const headerContent = useMemo(
        () =>
            hasDamages ? (
                <StyledButton color="primary" icon="ArrowForward" direction="row-reverse" iconSize="S" onClick={redirectToAllDamagedReports}>
                    <Text micro uppercase>
                        {t("insights.overview.allReports")}
                    </Text>
                </StyledButton>
            ) : null,
        [hasDamages, redirectToAllDamagedReports, t],
    );

    return (
        <StyledSection data-testid="damages-section">
            <TileWrapper isCollapsed={isCollapsed} maxHeight={maxHeight}>
                <div ref={wrapperRef}>
                    <StyledTile>
                        <StyledTileHeader>
                            <StyledIcon variant="Building" size="M" />
                            <Text uppercase micro>
                                {t("insights.damages.tile.header")}
                            </Text>
                            <Dot>•</Dot>
                            <Text data-testid="damages-section-sites-number" color="secondary" uppercase micro>
                                {t("insights.damages.sites", { count: selectedSiteIds.length })}
                            </Text>
                            <div />
                            {headerContent}
                        </StyledTileHeader>
                        <SiteCardsContainer>
                            {!!hasDamages && (
                                <>
                                    {damageReportsList.map(([siteId, reports]) => (
                                        <SiteCard key={siteId} siteId={siteId} reports={reports} />
                                    ))}
                                </>
                            )}
                            <EmptySiteCard
                                allSites={siteObjects}
                                filterApplied={siteObjects.length !== selectedSiteIds.length}
                                siteIdsWithDamages={damageReportsList.map(([siteId]) => siteId)}
                                siteIdsWithoutDamages={locationsWithoutDamage}
                            />
                        </SiteCardsContainer>
                    </StyledTile>
                </div>
            </TileWrapper>
            {hasShowMore ? (
                <ShowMoreWrapper isCollapsed={isCollapsed}>
                    <StyledButton color="accent" icon={isCollapsed ? "ArrowDown" : "ArrowUp"} direction="row" onClick={toggleCollapsed}>
                        <Text bold>{isCollapsed ? t("insights.damages.showMore") : t("insights.damages.showLess")}</Text>
                    </StyledButton>
                </ShowMoreWrapper>
            ) : null}
        </StyledSection>
    );
};
