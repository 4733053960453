import { useCallback } from "react";
import { useSelector } from "react-redux";
import { startOfDay, subMonths } from "src/helpers/date";

import UserSelectors from "../store/user/UserSelectors";
import { useFetchApi } from "./accessApi";

export const useInsightsApi = () => {
    const fetchApi = useFetchApi();
    const {
        info: { id },
    } = useSelector(UserSelectors.getUserInfo);
    const fetchDbAsync = useCallback(
        async (endpoint: string, locationIds: string[], userId?: string) => {
            const minDateTime = startOfDay(subMonths(new Date(), 18));
            const response = await fetchApi(
                endpoint,
                "POST",
                {
                    siteIds: locationIds,
                    minDateTime: minDateTime.toISOString(),
                    userId,
                },
                {
                    headers: {
                        Accept: "application/vnd.sqlite3",
                    },
                },
            );

            return await response.arrayBuffer();
        },
        [fetchApi],
    );

    const getReportsDb = async (locationIds: string[]) => {
        if (!locationIds?.length) {
            return Promise.resolve(null);
        }
        return await fetchDbAsync("/v1/global/insights/reports", locationIds, id);
    };

    return { getReportsDb };
};
