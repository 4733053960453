import { Graph, Palette, Spacing, Text } from "@secuis/ccp-react-components";
import { PieChart } from "recharts";
import styled from "styled-components";

import { ValueTile } from "../shared/components";

export const LegendItem = styled(Text).attrs({
    micro: true,
    bold: true,
})<{ backgroundColor: string }>`
    color: ${Palette.Navy750};
    padding: 0 ${Spacing.XXS}px;
    background-color: ${({ backgroundColor }) => `${Graph[backgroundColor]}`};
`;

export const ValueTileStyled = styled(ValueTile)`
    padding: 0;
`;

// hide focus outline when click on pie
// remove cursor pointer until redirection implemented
export const StyledPieChart = styled(PieChart)`
    path {
        cursor: default;
    }
    .recharts-sector:focus,
    .recharts-layer:focus,
    .recharts-surface:focus {
        outline: none;
    }
`;
