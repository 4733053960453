import { Breakpoints, Stack } from "@secuis/ccp-react-components";
import { formatFriendly, formatTime } from "src/helpers/date";
import { IsoStringDate } from "src/helpers/date/types";
import styled from "styled-components";

import { RightAlignText } from "./SharedStyles";

const ListItemDateText = styled(RightAlignText)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: ${Breakpoints.XS}) {
        line-height: 21px;
    }
`;

type Props = {
    date: IsoStringDate;
};

export const ListItemDate = ({ date }: Props) => {
    return (
        <Stack alignItems="flex-end" justifyContent="flex-end" gap="XS" data-testid="list-item-date">
            <ListItemDateText data-testid="list-date-text" micro bold>
                {`${formatFriendly(date, true)},`}
            </ListItemDateText>
            <ListItemDateText data-testid="list-time-text" micro bold>
                {formatTime(date)}
            </ListItemDateText>
        </Stack>
    );
};
