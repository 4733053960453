import { Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { IncidentColorScale, Pagination, SitesLegend, ToggleVisibleItems } from "./components";
import { useColumns, usePagination, useTableScroll, useToggleVisibleItems } from "./hooks";
import { TableStyled } from "./Matrix.styles";
import { MatrixProps, MatrixTableProps } from "./Matrix.types";

/**
 *
 * @param categories eg. [{ categoryKey: "criminal_act_or_suspicious_behavior", categoryKey: "Facility", ... }]
 * @param data eg. [{ site: { name: "Cincinnati", total: 100 }, criminal_act_or_suspicious_behavior: { count: 10, total: 100 }, ...]
 */
export const Matrix = ({ categories, data }: MatrixProps) => {
    const MAX_COLUMNS_COUNT_TO_ENABLE_MAX_WIDTH = 4;
    const MAX_INITIAL_ITEMS_COUNT = 5;
    const MAX_VISIBLE_ITEMS_COUNT_WITHOUT_SCROLL = 10;

    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const categoriesPerPage = isMobile ? 2 : 4;

    const {
        totalPages,
        currentItems: visibleCategories,
        currentPage,
        showPreviousPage,
        showNextPage,
    } = usePagination({
        items: categories,
        itemsPerPage: categoriesPerPage,
    });

    const { visibleItems, showMoreItems, showLessItems } = useToggleVisibleItems({
        items: data,
        maxVisibleItems: MAX_INITIAL_ITEMS_COUNT,
    });

    const columns = useColumns(visibleCategories, visibleItems);

    const { handleOnScroll, tableRef, sitesLegend } = useTableScroll();

    useEffect(() => {
        // init or update Sites Legend on mobile and when user clicks on Load More/Load Less
        isMobile && handleOnScroll(tableRef.current);
    }, [isMobile, visibleItems]);

    const MatrixTable = React.forwardRef<HTMLDivElement, MatrixTableProps>((props, ref) => {
        return (
            <div ref={ref}>
                <TableStyled
                    data={props.data}
                    columns={props.columns}
                    colMaxWidthEnabled={props.colMaxWidthEnabled}
                    scrollable={props.scrollable}
                    categoryColumnCount={props.categoryColumnCount}
                    rowGroupsEnabled={true}
                    onScroll={props.onScroll}
                />
            </div>
        );
    });

    MatrixTable.displayName = "MatrixTable";

    return (
        <>
            {categories.length > categoriesPerPage && (
                <Pagination currentPage={currentPage} totalPages={totalPages} showPreviousPage={showPreviousPage} showNextPage={showNextPage} />
            )}

            <MatrixTable
                ref={tableRef}
                data={visibleItems}
                columns={columns}
                colMaxWidthEnabled={columns.length <= MAX_COLUMNS_COUNT_TO_ENABLE_MAX_WIDTH}
                scrollable={visibleItems.length > MAX_VISIBLE_ITEMS_COUNT_WITHOUT_SCROLL}
                rowGroupsEnabled={true}
                categoryColumnCount={visibleCategories.length}
                onScroll={(el) => {
                    isMobile && handleOnScroll(el.currentTarget);
                }}
            />

            {sitesLegend.length > 0 && <SitesLegend sites={sitesLegend} />}

            <Stack justifyContent="space-between" mt="M" flexWrap="wrap" gap="S">
                {data.length > MAX_INITIAL_ITEMS_COUNT && (
                    <ToggleVisibleItems
                        items={visibleItems}
                        maxItems={MAX_VISIBLE_ITEMS_COUNT_WITHOUT_SCROLL}
                        showMoreItems={showMoreItems}
                        showLessItems={showLessItems}
                        showMoreItemsText={t("incidentMatrix.table.show_more")}
                        showLessItemsText={t("incidentMatrix.table.show_less")}
                    />
                )}

                <IncidentColorScale />
            </Stack>
        </>
    );
};
