import { Breakpoints, EmptyState, Skeleton, Spacing, Stack } from "@secuis/ccp-react-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { CARDS_PAGE_LIMIT, HighlightsType } from "../../../sql/scripts/insights/highlightsQueries";
import { IHighlightsCard } from "../../../store/insights/InsightsModel";
import ReportCategoriesSelectors from "../../../store/reportCategories/ReportCategoriesSelectors";
import { HighlightsCard } from "./Cards";
import { criticalCardHeight, criticalCardWidth } from "./Cards.styled";
import { CardsCarousel } from "./CardsCarousel";

const CardsWrapper = styled.div`
    padding: 0 0 ${Spacing.S}px 0;
    overflow-x: hidden;
`;

const StyledBox = styled(Stack)`
    height: ${criticalCardHeight + 16}px;
`;

const StyledStack = styled(Stack)`
    @media (max-width: ${Breakpoints.XS}) {
        width: ${4 * (criticalCardWidth + 16)}px;
    }
`;

type Props = {
    cards: IHighlightsCard[];
    isLoading: boolean;
    onHoverCard?: (card: IHighlightsCard) => void;
    loadNextPage: () => void;
};

export const RedAndPurpleCards = ({ cards, isLoading, onHoverCard, loadNextPage }: Props) => {
    const { t } = useTranslation();
    const preventionCategories = useSelector(ReportCategoriesSelectors.getPreventedCategories);
    const onScroll = useCallback(
        (event) => {
            const { abs, slides } = event.track.details;
            if (abs > slides.length - CARDS_PAGE_LIMIT / 2) {
                loadNextPage();
            }
        },
        [loadNextPage],
    );

    const getHighlightsType = useCallback(
        (categoryLevel3: string): HighlightsType => {
            if (preventionCategories.includes(categoryLevel3)) {
                return "prevention_categories";
            }
            return "critical_categories";
        },
        [preventionCategories],
    );

    if (isLoading) {
        return (
            <CardsWrapper data-testid="cards-loading">
                <StyledStack gap="S">
                    <Skeleton mode="rectangular" width={criticalCardWidth} height={criticalCardHeight} />
                    <Skeleton mode="rectangular" width={criticalCardWidth} height={criticalCardHeight} />
                    <Skeleton mode="rectangular" width={criticalCardWidth} height={criticalCardHeight} />
                    <Skeleton mode="rectangular" width={criticalCardWidth} height={criticalCardHeight} />
                </StyledStack>
            </CardsWrapper>
        );
    }

    if (!cards.length) {
        return (
            <StyledBox alignItems="center" justifyContent="center">
                <EmptyState icon="DowntimeUnplanned" title={t("insights.map.emptyCardsState")} />
            </StyledBox>
        );
    }

    return (
        <CardsWrapper data-testid="cards-carousel-wrapper">
            <CardsCarousel onDragEnded={onScroll} cardWidth={criticalCardWidth}>
                {cards.map((card, index) => (
                    <HighlightsCard onHoverCard={onHoverCard} card={card} index={index} highlightsType={getHighlightsType(card.categoryLevel3)} key={card.id} />
                ))}
            </CardsCarousel>
        </CardsWrapper>
    );
};
