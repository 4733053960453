import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { featureFlags } from "../../data/featureFlags";
import { useFilterableReportTypes } from "../../hooks/ReportTypeHooks";
import { Databases } from "../../models/DbModel";
import { CategoryLevel1Keys } from "../../models/ReportCategoryModel";
import { ReportTemplateEnum } from "../../models/ReportModel";
import queries from "../../sql/scripts/insights/graphOverviewQueries";
import ReportCategoriesSelectors from "../reportCategories/ReportCategoriesSelectors";
import { isFinished, RequestStatus } from "../RequestStatus";
import { useFilteredSites } from "./FilterHooks";
import InsightsActions from "./InsightsActions";
import { useDatabase } from "./InsightsLoadHooks";
import { IGraphTile } from "./InsightsModel";
import InsightsSelectors from "./InsightsSelectors";
import { generateQueryKey } from "./keys";

export const useGraphOverviewRedirection = () => {
    const navigate = useNavigate();
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const selectedRegions = useSelector(InsightsSelectors.getSelectedRegions);
    const reportCategories = useSelector(ReportCategoriesSelectors.getReportCategories);
    const { defaultInsightsReportTypes } = useFilterableReportTypes();
    const isAssignmentReportTypeEnabled = useBooleanFlagValue(featureFlags.assignmentReports, false);
    const isCalloutReportTypeEnabled = useBooleanFlagValue(featureFlags.calloutReports, false);

    const redirectToCategoryLevel1Reports = useCallback(
        (categoryLevel1) => {
            navigate(
                `/reports?selectedCategoryOne=${categoryLevel1}&selectedRegions=${selectedRegions.toString()}&selectedStartDate=${selectedStartDate.toISOString()}&selectedEndDate=${selectedEndDate.toISOString()}&selectedReportTypes=${defaultInsightsReportTypes.toString()}`,
            );
        },
        [navigate, selectedRegions, selectedStartDate, selectedEndDate, defaultInsightsReportTypes],
    );
    const redirectToCategoryLevel2Reports = useCallback(
        (categoryLevel2) => {
            navigate(
                `/reports?selectedCategoryTwo=${categoryLevel2}&selectedRegions=${selectedRegions.toString()}&selectedStartDate=${selectedStartDate.toISOString()}&selectedEndDate=${selectedEndDate.toISOString()}&selectedReportTypes=${defaultInsightsReportTypes.toString()}`,
            );
        },
        [navigate, selectedRegions, selectedStartDate, selectedEndDate, defaultInsightsReportTypes],
    );
    const redirectToSeverityLevelReports = useCallback(
        (categoryLevel1, severityLevel) => {
            navigate(
                `/reports?selectedCategoryOne=${categoryLevel1}&selectedSeverityLevels=${severityLevel}&selectedRegions=${selectedRegions.toString()}&selectedStartDate=${selectedStartDate.toISOString()}&selectedEndDate=${selectedEndDate.toISOString()}&selectedReportTypes=${defaultInsightsReportTypes.toString()}`,
            );
        },
        [navigate, selectedRegions, selectedStartDate, selectedEndDate, defaultInsightsReportTypes],
    );
    const redirectToSiteLocationIncidents = useCallback(
        (siteLocations) => {
            const allLevel1Categories = reportCategories.reportCategoriesLevel1.map((l) => l.key).join(",");
            const reportTypesFilter = [ReportTemplateEnum.alarmResponse, ReportTemplateEnum.incident];
            if (isAssignmentReportTypeEnabled) {
                reportTypesFilter.push(ReportTemplateEnum.assignment);
            }
            if (isCalloutReportTypeEnabled) {
                reportTypesFilter.push(ReportTemplateEnum.callout);
            }
            navigate(
                `/reports?selectedCategoryOne=${allLevel1Categories}&selectedSiteLocations=${siteLocations}&selectedRegions=${selectedRegions.toString()}&selectedStartDate=${selectedStartDate.toISOString()}&selectedEndDate=${selectedEndDate.toISOString()}&selectedReportTypes=${reportTypesFilter.toString()}`,
            );
        },
        [navigate, isAssignmentReportTypeEnabled, isCalloutReportTypeEnabled, reportCategories, selectedRegions, selectedStartDate, selectedEndDate],
    );
    return { redirectToCategoryLevel1Reports, redirectToCategoryLevel2Reports, redirectToSeverityLevelReports, redirectToSiteLocationIncidents };
};

export const useGraphOverviewInsights = (): {
    isLoading: boolean;
    reportsCount: number;
    tiles: IGraphTile[];
} => {
    const dispatch = useDispatch();
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const { siteIds } = useFilteredSites();
    const { currentQuery, reportsCount, tiles } = useSelector(InsightsSelectors.getGraphOverviewInsights);

    const isLoading = useMemo(() => {
        return !dbReadyToQuery || !isFinished(currentQuery?.status);
    }, [dbReadyToQuery, currentQuery]);

    const getTop5Query = useCallback((category: string, locationIds: string[], fromDate: Date, toDate: Date) => {
        return queries.top5.query(category, locationIds, fromDate.toISOString(), toDate.toISOString());
    }, []);

    const getBySeverityLevelQuery = useCallback((category: string, locationIds: string[], fromDate: Date, toDate: Date) => {
        return queries.bySeverityLevel.query(category, locationIds, fromDate.toISOString(), toDate.toISOString());
    }, []);

    const getOverviewByCategory = useCallback(
        async (category: string, locationIds: string[], fromDate: Date, toDate: Date) => {
            const top5Query = getTop5Query(category, locationIds, fromDate, toDate);
            const top5Job = execute(top5Query.sql, top5Query.params);
            const bySeverityLevelQuery = getBySeverityLevelQuery(category, locationIds, fromDate, toDate);
            const bySeverityLevelJob = execute(bySeverityLevelQuery.sql, bySeverityLevelQuery.params);
            const overviews = await Promise.all([top5Job, bySeverityLevelJob]);

            return {
                category: category,
                top5: queries.top5.getResults(overviews[0]?.results),
                bySeverityLevel: queries.bySeverityLevel.getResults(overviews[1]?.results),
            };
        },
        [execute, getBySeverityLevelQuery, getTop5Query],
    );

    const getOverviewCount = useCallback(
        async (locationIds: string[], fromDate: Date, toDate: Date) => {
            const countQuery = queries.reportsCount.query(locationIds, fromDate.toISOString(), toDate.toISOString());
            const countExecution = await execute(countQuery.sql, countQuery.params);
            return queries.reportsCount.getResults(countExecution?.results);
        },
        [execute],
    );

    useEffect(() => {
        const getGraphOverview = async (key) => {
            dispatch(InsightsActions.requestGraphOverviewInsights(key));
            const criminal = getOverviewByCategory(CategoryLevel1Keys.criminalActOrSuspiciousBehavior, siteIds, selectedStartDate, selectedEndDate);
            const facility = getOverviewByCategory(CategoryLevel1Keys.facility, siteIds, selectedStartDate, selectedEndDate);
            const hs = getOverviewByCategory(CategoryLevel1Keys.healthAndSafety, siteIds, selectedStartDate, selectedEndDate);
            const people = getOverviewByCategory(CategoryLevel1Keys.peopleAndAssets, siteIds, selectedStartDate, selectedEndDate);
            const overviewCount = await getOverviewCount(siteIds, selectedStartDate, selectedEndDate);
            const graphOverview = await Promise.all([criminal, facility, hs, people]);
            dispatch(InsightsActions.graphOverviewInsightsSuccess(key, graphOverview, overviewCount));
        };
        const key = generateQueryKey({ locationIds: siteIds, fromDate: selectedStartDate, toDate: selectedEndDate });
        if (!dbReadyToQuery || (currentQuery?.key === key && (currentQuery?.status === RequestStatus.loading || isFinished(currentQuery?.status)))) {
            return;
        }
        getGraphOverview(key);
    }, [dbReadyToQuery, selectedStartDate, selectedEndDate, siteIds, currentQuery, dispatch, getOverviewByCategory, getOverviewCount]);

    return { isLoading, tiles, reportsCount };
};
