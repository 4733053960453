interface IUserName {
    first: string;
    middle: string;
    last: string;
}

interface IUserEmail {
    address: string;
    isVerified: boolean;
    isPrimary: boolean;
}

export interface IUserPreferences {
    privacyPolicyDateAcceptedV4?: string;
    termsOfUseDateAcceptedV3?: string;
    language?: string;
    onboardingCompletedDate?: string;
    optInForNonAnonymizedAnalytics?: boolean;
    appGuideProgress?: string;
    top5IncidentsCategories: string;
}

export enum UserPreference {
    PrivacyPolicyDateAcceptedV4 = "privacyPolicyDateAcceptedV4",
    TermsOfUseDateAcceptedV3 = "termsOfUseDateAcceptedV3",
    Language = "language",
    OnboardingCompletedDate = "onboardingCompletedDate",
    AppGuideProgress = "appGuideProgress",
    Top5IncidentsCategories = "top5IncidentsCategories",
}

export interface IUser {
    id: string;
    status: string;
    name: IUserName;
    fullName?: string;
    emails?: IUserEmail[];
    emailPrimary?: string;
    preferences: IUserPreferences;
    isEmployee: boolean;
}

export function getUserPrimaryEmail(emails: IUserEmail[]): string {
    if (emails?.length) {
        let primaryEmail = emails.find((email) => email.isPrimary);
        if (!primaryEmail) {
            primaryEmail = emails[0];
        }
        return primaryEmail.address;
    }
}

export function getUserFullName(name: IUserName): string {
    let combinedName = null;
    if (name) {
        combinedName = name.first;
        if (name.middle) {
            combinedName += " " + name.middle;
        }
        if (name.last) {
            combinedName += " " + name.last;
        }
    }
    return combinedName;
}

export const getUserLabel = ({ name, emails }: IUser): string => {
    // if name is set then return name
    let label = getUserFullName(name);
    if (!label) {
        // otherwise return email
        label = getUserPrimaryEmail(emails);
    }
    return label;
};

const resolveType = (value) => {
    // Cast boolean strings to boolean
    if (value === "true" || value === "false") {
        value = value === "true";
    }

    return value;
};

const mapKeyValueToUserPreference = (pairs: { key: string; value: any }[]) => {
    const result = {};

    if (pairs && pairs.length > 0) {
        pairs.forEach(({ key, value }) => {
            result[key] = resolveType(value);
        });
    }

    return result as IUserPreferences;
};

export function mapToUser(userDTO: any): IUser {
    return {
        id: userDTO.id,
        status: userDTO.Status,
        name: userDTO.name,
        fullName: getUserFullName(userDTO.name),
        emails: userDTO.emails,
        emailPrimary: userDTO.emails ? getUserPrimaryEmail(userDTO.emails) : userDTO.primaryEmailAddress,
        isEmployee: userDTO.isEmployee,
        preferences: mapKeyValueToUserPreference(userDTO.preferences),
    };
}

export interface IUserListItem {
    id: string;
    image?: any;
    canEdit: boolean;
    isEditing?: boolean;
    subTitle?: string;
    title?: string;
    isPending: boolean;
    descendantSitesCount?: string;
}
