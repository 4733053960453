import { ButtonIcon, Icon, IconType, Stack, Text, Title } from "@secuis/ccp-react-components";
import { StackProps } from "@secuis/ccp-react-components/dist/types/styles/stack/Stack";

import { QaProps, StyleProps } from "../../../../../../models";
import { TrendInfo } from "./TrendInfo";
import { SkeletonStyled, StackStyled, TileWrapperStyled } from "./ValueTile.styles";

type Props = QaProps &
    StyleProps & {
        title?: string;
        label?: string;
        value: number;
        previousValue?: number;
        compareDescription?: string;
        icon?: IconType;
        onRedirect?: () => void;
        direction?: "column" | "row";
        align?: "left" | "right";
        isLoading?: boolean;
    };

export const ValueTile = ({
    title,
    value,
    label,
    previousValue,
    compareDescription,
    icon,
    direction = "column",
    align = "left",
    onRedirect,
    isLoading,
    ...otherProps
}: Props) => {
    const isClickable = !!onRedirect && !isLoading;

    const alignItems: StackProps["alignItems"] = direction === "row" || align === "right" ? "flex-end" : "flex-start";
    const justifyContent: StackProps["justifyContent"] = direction === "row" && align === "right" ? "flex-end" : "flex-start";

    return (
        <TileWrapperStyled {...otherProps} isClickable={isClickable}>
            {isLoading ? (
                <SkeletonStyled mode="rectangular" />
            ) : (
                <>
                    {title && (
                        <Stack justifyContent="space-between">
                            <Stack alignItems="center" gap="XS">
                                {icon && <Icon color="secondary" variant={icon} />}
                                <Text data-testid="title" bold={true} color="secondary">
                                    {title}
                                </Text>
                            </Stack>
                            {isClickable && (
                                <ButtonIcon data-testid="go-forward-button" onClick={onRedirect} icon="ArrowForward" mode="stateless" iconSize="L"></ButtonIcon>
                            )}
                        </Stack>
                    )}

                    <StackStyled direction={direction} alignItems={alignItems} justifyContent={justifyContent}>
                        <Stack alignItems="baseline" gap="XS">
                            <Title data-testid="main-value">{value}</Title>
                            {label && <Text small={true}>{label}</Text>}
                        </Stack>

                        <TrendInfo data-testid="trend-info" value={value} previousValue={previousValue} description={compareDescription} align={align} />
                    </StackStyled>
                </>
            )}
        </TileWrapperStyled>
    );
};
