import { IsoStringDate } from "src/helpers/date";
import { WorkerExecResult } from "src/models/DbModel";
import { getParameterizedArray, getSqlStringifiedArrayFromObject } from "src/sql/scripts/queryUtilities";

import { IncidentData } from "./Matrix.types";

type Site = {
    id: string;
    total: number;
};

type MatrixDataQueryResult = {
    site: Site;
    [key: string]: Site | IncidentData;
};

export const getMatrixDataQuery = ({
    siteIds,
    startDate,
    endDate,
    categories,
}: {
    siteIds: string[];
    startDate: IsoStringDate;
    endDate: IsoStringDate;
    categories: string[];
}) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT 
                location_id,
                ${categories.map((category) => `COUNT(CASE WHEN category_level1 = '${category}' THEN 1 END) AS ${category}`).join(", ")}
            FROM reports
            WHERE
                report_date_time >= STRFTIME('%Y-%m-%d %H:%M:%S', $startDate)
                AND report_date_time <= STRFTIME('%Y-%m-%d %H:%M:%S', $endDate)
                AND location_id IN (${stringifiedSites})
            GROUP BY location_id
        `,
        params: {
            $startDate: startDate,
            $endDate: endDate,
            ...siteIdsParams,
        },
    };
};

export const parseMatrixDataQueryResult = ({ results }: WorkerExecResult): MatrixDataQueryResult[] => {
    const columns = results[0]?.columns;
    const values = results[0]?.values;

    if (!values?.length) {
        return [];
    }

    return values.map((valueRow) => {
        let totalIncidentCount = 0;

        const categories = columns.reduce(
            (acc, columnName, index) => {
                if (columnName !== "location_id") {
                    const categoryIncidentCount = valueRow[index] as number;
                    totalIncidentCount += categoryIncidentCount;

                    acc[columnName] = {
                        categoryIncidentCount: categoryIncidentCount,
                        totalIncidentCount: 0,
                    };
                }
                return acc;
            },
            {} as { [key: string]: { categoryIncidentCount: number | string; totalIncidentCount: number } },
        );

        // assign calculated totalIncidentCount value to each category
        Object.keys(categories).forEach((key) => {
            categories[key].totalIncidentCount = totalIncidentCount;
        });

        const site = {
            id: valueRow[columns.indexOf("location_id")],
            total: totalIncidentCount,
        };

        const siteData = {
            site,
            ...categories,
        };

        return siteData;
    });
};
