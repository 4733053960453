import { HighlightsCategories } from "./highlightsQueries";

export const preventionCategories: HighlightsCategories[] = [
    {
        categoryLevel2: "cas_burglary",
        categoriesLevel3: ["cas_attempted"],
    },
    {
        categoryLevel2: "cas_physical_violence",
        categoriesLevel3: ["cas_attempted_armed_assault", "cas_attempted_fight_brawl", "cas_attempted_unarmed_assault", "cas_attempted_suicide"],
    },
    {
        categoryLevel2: "cas_robbery",
        categoriesLevel3: ["cas_attempted_armed_robbery", "cas_attempted_unarmed_robbery"],
    },
    {
        categoryLevel2: "cas_theft",
        categoriesLevel3: ["cas_attempted_fraud", "cas_attempted_shoplifting", "cas_attempted_stealing_of_property"],
    },
    {
        categoryLevel2: "cas_tresspassing",
        categoriesLevel3: ["cas_attempted_unauthorized_access"],
    },
    {
        categoryLevel2: "cas_vandalism",
        categoriesLevel3: [
            "cas_attempted_equipment_damage",
            "cas_attempted_graffiti",
            "cas_attempted_property_damage",
            "cas_attempted_structural_damage",
            "cas_attempted_vehicle_vandalism",
        ],
    },
];

export const criticalCategories: HighlightsCategories[] = [
    {
        categoryLevel2: "cas_abduction",
        categoriesLevel3: ["cas_abduction_confirmed", "cas_abduction_suspected"],
    },
    {
        categoryLevel2: "cas_abuse",
        categoriesLevel3: ["cas_domestic_violence", "cas_verbal", "cas_abuse_physical"],
    },
    {
        categoryLevel2: "cas_burglary",
        categoriesLevel3: ["cas_with_damage", "cas_without_damage"],
    },
    {
        categoryLevel2: "cas_disastrous_event",
        categoriesLevel3: ["cas_chemical_biological_attack", "cas_shooting", "cas_suspected_terrorism"],
    },
    {
        categoryLevel2: "cas_drugs_and_alcohol",
        categoriesLevel3: ["cas_found_recovered", "cas_intoxication"],
    },
    {
        categoryLevel2: "cas_harassment",
        categoriesLevel3: ["cas_discrimination", "cas_sexual_harassment"],
    },
    {
        categoryLevel2: "cas_illegal_possession",
        categoriesLevel3: ["cas_confirmed_possession", "cas_suspected_posession"],
    },
    {
        categoryLevel2: "cas_other",
        categoriesLevel3: ["cas_bribery", "cas_illegal_disposal_of_waste"],
    },
    {
        categoryLevel2: "cas_physical_violence",
        categoriesLevel3: [
            "cas_armed_assault_melee_weapon",
            "cas_armed_assault_with_firearm",
            "cas_fight_brawl",
            "cas_violence_self_harm",
            "cas_sexual_assault",
            "cas_suicide",
            "cas_unarmed_assault",
        ],
    },
    {
        categoryLevel2: "cas_robbery",
        categoriesLevel3: ["cas_armed_robbery", "cas_unarmed_robbery"],
    },
    {
        categoryLevel2: "cas_theft",
        categoriesLevel3: ["cas_fraud", "cas_shoplifting", "cas_stolen_property"],
    },
    {
        categoryLevel2: "cas_threat",
        categoriesLevel3: [
            "cas_self_harm",
            "cas_blackmailing",
            "cas_bomb_threat",
            "cas_client_threat",
            "cas_hazardous_material",
            "cas_hostage_situation",
            "cas_mail_threat",
            "cas_personal_threat",
        ],
    },
    {
        categoryLevel2: "cas_tresspassing",
        categoriesLevel3: ["cas_soliciting", "cas_unauthorized_access"],
    },
    {
        categoryLevel2: "cas_unusual_behavior",
        categoriesLevel3: ["cas_loitering", "cas_radical_behaviour", "cas_suspicious_behavior"],
    },
    {
        categoryLevel2: "cas_vandalism",
        categoriesLevel3: ["cas_equipment_damage", "cas_graffiti", "cas_property_damage", "cas_structural_damage", "cas_vehicle_vandalised"],
    },
    {
        categoryLevel2: "fac_access_control_systems",
        categoriesLevel3: ["fac_access_control_systems_breached"],
    },
    {
        categoryLevel2: "fac_elevator_stairs_escalators",
        categoriesLevel3: ["fac_emergency_phone_call", "fac_emergency_stop", "fac_elevator_stairs_escalators_entrapment"],
    },
    {
        categoryLevel2: "fac_fire_systems",
        categoriesLevel3: ["fac_fire_systems_triggered"],
    },
    {
        categoryLevel2: "fac_heating_ventilation_air_condition",
        categoriesLevel3: ["fac_tampered_with"],
    },
    {
        categoryLevel2: "fac_security_systems",
        categoriesLevel3: ["fac_security_systems_triggered", "fac_security_systems_breached"],
    },
    {
        categoryLevel2: "fac_water_and_sanitary",
        categoriesLevel3: ["fac_flooding"],
    },
    {
        categoryLevel2: "has_accident",
        categoriesLevel3: [
            "has_accident_slip_and_fall",
            "has_accident_vehicle",
            "has_accident_contact_heat_chemical_electricity",
            "has_accident_equipment",
            "has_accident_gate_arm",
            "has_accident_improper_handling",
            "has_accident_inhalation_absorption_ingestion",
            "has_accident_struck_against_object",
        ],
    },
    {
        categoryLevel2: "has_active_hazard",
        categoriesLevel3: [
            "has_explosion",
            "has_active_hazard_fire",
            "has_gas_leak",
            "has_hazardous_environment",
            "has_hazardous_material",
            "has_liquid_leak",
            "has_active_hazard_natural_disasters",
            "has_temperature_deviation",
        ],
    },
    {
        categoryLevel2: "has_medical",
        categoriesLevel3: ["has_emergency", "has_fatality"],
    },
    {
        categoryLevel2: "has_other_health_and_safety",
        categoriesLevel3: ["has_missing_person", "has_other_health_and_safety_police_activity", "has_trapped"],
    },
    {
        categoryLevel2: "has_public_disturbance",
        categoriesLevel3: ["has_disturbing_indecent_behaviour", "has_protest_demonstration"],
    },
    {
        categoryLevel2: "has_workplace_violation",
        categoriesLevel3: ["has_sexual_harassment"],
    },
    {
        categoryLevel2: "ise_guard_uniform",
        categoriesLevel3: ["ise_guard_uniform_stolen"],
    },
    {
        categoryLevel2: "ise_securitas_guard_assets_equipment",
        categoriesLevel3: ["ise_stolen"],
    },
    {
        categoryLevel2: "paa_access_control",
        categoriesLevel3: ["paa_forbidden_item"],
    },
    {
        categoryLevel2: "paa_emergency_services",
        categoriesLevel3: ["paa_emergency_services_triggered"],
    },
    {
        categoryLevel2: "paa_goods_and_products",
        categoriesLevel3: ["paa_suspicious"],
    },
    {
        categoryLevel2: "paa_vehicle_and_parking",
        categoriesLevel3: ["paa_vehicle_and_parking_hit_and_run"],
    },
];

export const accessControlCategories: HighlightsCategories[] = [
    {
        categoryLevel2: "paa_access_control",
        categoriesLevel3: [
            "paa_unauthorized_access",
            "paa_access_denied",
            "paa_badge_violation",
            "paa_forbidden_item",
            "paa_access_control_ticket_violation",
            "paa_access_control_visitation_policy_violation",
        ],
    },
];

export const accessControlSystemsCategories: HighlightsCategories[] = [
    {
        categoryLevel2: "fac_access_control_systems",
        categoriesLevel3: [
            "fac_access_control_systems_breached",
            "fac_access_control_systems_error_fault_alert",
            "fac_access_control_systems_malfunctioning",
            "fac_access_control_systems_triggered",
        ],
    },
];

export const medicalAssistanceCategories: HighlightsCategories[] = [
    {
        categoryLevel2: "has_medical",
        categoriesLevel3: ["has_disoriented", "has_emergency", "has_illness", "has_fatality", "has_injury"],
    },
];

export const confrontationCategories: HighlightsCategories[] = [
    {
        categoryLevel2: "has_dispute_confrontation",
        categoriesLevel3: ["has_dispute", "has_confronted"],
    },
];

export const equipmentAccidentCategories: HighlightsCategories[] = [
    {
        categoryLevel2: "has_accident",
        categoriesLevel3: [
            "has_accident_contact_heat_chemical_electricity",
            "has_accident_equipment",
            "has_accident_gate_arm",
            "has_accident_improper_handling",
            "has_accident_inhalation_absorption_ingestion",
            "has_accident_slip_and_fall",
            "has_accident_struck_against_object",
            "has_accident_vehicle",
        ],
    },
];

export const reportedAuditsCategories: HighlightsCategories[] = [
    {
        categoryLevel2: "has_inspections_audits",
        categoriesLevel3: ["has_fire_audit_violation", "has_safety_audit_violation", "has_security_inspection_violation"],
    },
];
