import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Databases } from "../../models/DbModel";
import { CustomizedCategories } from "../../pages/Insights/Trends/TrendingEvents/TrendingEvents";
import queries from "../../sql/scripts/insights/trendingEventQueries";
import { useFilteredSites } from "./FilterHooks";
import InsightsActions from "./InsightsActions";
import { useDatabase } from "./InsightsLoadHooks";
import { ITrendingEvent } from "./InsightsModel";
import InsightsSelectors from "./InsightsSelectors";

export const useTrendingEvents = (
    selectedCategories: CustomizedCategories,
): {
    isLoading: boolean;
    events: ITrendingEvent[];
} => {
    const dispatch = useDispatch();
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const { events } = useSelector(InsightsSelectors.getTrendingEventsState);
    const { siteIds } = useFilteredSites();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(false);
    }, [siteIds, selectedCategories]);

    const queryTrendingEvents = useCallback(
        async (locationIds: string[], queryCategories: CustomizedCategories) => {
            try {
                const trendingEventsQuery = queries.trendingEvents.query(locationIds, queryCategories);
                const trendingEventsJob = execute(trendingEventsQuery.sql, trendingEventsQuery.params);
                const queryResults = await Promise.all([trendingEventsJob]);
                return queries.trendingEvents.getResults(queryResults[0]?.results, queryCategories);
            } finally {
                setIsLoaded(true);
            }
        },
        [execute],
    );

    useEffect(() => {
        const getTrendingEvents = async () => {
            dispatch(InsightsActions.requestTrendingEvents());
            const data = await queryTrendingEvents(siteIds, selectedCategories);
            dispatch(InsightsActions.trendingEventsSuccess(data));
        };
        if (!dbReadyToQuery || isLoaded) {
            return;
        }
        getTrendingEvents();
    }, [dbReadyToQuery, isLoaded, siteIds, dispatch, queryTrendingEvents, selectedCategories]);

    return { isLoading: !isLoaded || !dbReadyToQuery, events };
};
