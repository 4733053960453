import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const PaginationStyled = styled(Stack)`
    position: absolute;
    top: 0;
    right: 0;
`;

export const ButtonStyled = styled.button`
    height: 32px;
`;
