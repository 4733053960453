import { Separator, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "src/helpers/date";
import { PatrolTourType } from "src/models/ReportModel";
import { ISiteObject } from "src/models/SiteObjectModel";
import styled from "styled-components";

import { TextWithIcon } from "../TextWithIcon";
import { ViewersSummary } from "../ViewersSummary";
import { ReportedBy } from "./ReportedBy";

type Props = {
    patrol: PatrolTourType;
    siteObject: ISiteObject;
    isShared: boolean;
};

const StackStyled = styled(Stack)`
    row-gap: ${Spacing.XS}px;
    flex-wrap: wrap;
`;

export const PatrolDetailsHeaderLine1 = ({ patrol, siteObject, isShared }: Props) => {
    const { t } = useTranslation();
    return (
        <StackStyled direction="row">
            <Text data-testid="detail-view-date-container" color="secondary" micro>
                {formatDateTime(patrol.endDateTime)}
            </Text>
            <Separator variant="vertical" mh="S" />
            <TextWithIcon id="patrol-details__duration" color="secondary" variant="Time" iconSize="S">
                {t("common.minutes", { count: patrol.durationMinutes ?? 0 })}
            </TextWithIcon>
            <Separator variant="vertical" mh="S" />
            {!isShared && <ReportedBy reportedBy={patrol.performedBy} countryCode={siteObject.countryCode} isTour />}
            <ViewersSummary visitorIds={patrol.visitorIds} visitorsCount={patrol.visitorsCount} isShared={isShared} />
        </StackStyled>
    );
};
