import { TileLoader } from "../../../components/Insights/TileLoader/TileLoader";
import { useDamageReports } from "../../../store/insights/DamageReportsHooks";
import { ReportedDamagesTile } from "./ReportedDamagesTile";

export const ReportedDamages = () => {
    const { damageReports, isLoading } = useDamageReports();
    if (isLoading) {
        return <TileLoader height={"500px"} />;
    }
    return <ReportedDamagesTile damageReports={damageReports} />;
};
