import { useTranslation } from "react-i18next";
import { Loading } from "src/components/shared/Loading";

import InsightHeader from "../../../components/Insights/common/InsightHeader";
import { Matrix } from "./Matrix";
import { EmptyState } from "./Matrix/components";
import { useMatrixData } from "./Matrix/hooks";
import { MATRIX_REPORT_CATEGORIES as categories } from "./Matrix/Matrix.constants";
import { MatrixStyled } from "./Matrix/Matrix.styles";

export const IncidentMatrix = () => {
    const { isLoading, totalIncidentCount, data: matrixData } = useMatrixData(categories);
    const { t } = useTranslation();

    return (
        <MatrixStyled p="L" data-testid="matrix-table-wrapper">
            <InsightHeader title={t("incidentMatrix.header")} />

            {isLoading ? <Loading /> : totalIncidentCount > 0 ? <Matrix categories={categories} data={matrixData} /> : <EmptyState />}
        </MatrixStyled>
    );
};
