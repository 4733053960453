import { useState } from "react";

type ToggleVisibleItemsProps<T> = {
    items: T[];
    maxVisibleItems: number;
};

export const useToggleVisibleItems = <T>({ items, maxVisibleItems }: ToggleVisibleItemsProps<T>) => {
    const [visibleItems, setVisibleItems] = useState(items.slice(0, maxVisibleItems));

    const showLessItems = () => {
        setVisibleItems(items.slice(0, maxVisibleItems));
    };

    const showMoreItems = () => {
        setVisibleItems(items);
    };

    return {
        visibleItems,
        showLessItems,
        showMoreItems,
    };
};
