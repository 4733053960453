import { Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { ColorScaleStyled, IncidentColorScaleStyled } from "./IncidentColorScale.styles";

export const IncidentColorScale = () => {
    const { t } = useTranslation();

    return (
        <IncidentColorScaleStyled data-testid="matrix-color-scale">
            <Text small color="secondary">
                {t("incidentMatrix.table.footer.color_scale.incidents")}
            </Text>
            <ColorScaleStyled marginVertical="XS" />
            <Stack marginVertical="XS" justifyContent="space-between">
                <Text small color="secondary">
                    0
                </Text>
                <Text small color="secondary">
                    100
                </Text>
            </Stack>
        </IncidentColorScaleStyled>
    );
};
