import { IsoStringDate } from "src/helpers/date";

import { WorkerExecResult } from "../../../../models/DbModel";
import { getParameterizedArray, getSqlStringifiedArrayFromObject } from "../../../../sql/scripts/queryUtilities";
import { ReportsCountData } from "./ReportsCountWidget.types";

export const getReportsCountQuery = ({ siteIds, startDate, endDate }: { siteIds: string[]; startDate: IsoStringDate; endDate: IsoStringDate }) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT count(*) as reportsCount, count(DISTINCT location_id) as sitesCount
            FROM reports
            WHERE
                category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND report_date_time >= STRFTIME('%Y-%m-%d %H:%M:%S', $startDate)
                AND report_date_time <= STRFTIME('%Y-%m-%d %H:%M:%S', $endDate)
                AND location_id IN (${stringifiedSites})
        `,
        params: {
            $startDate: startDate,
            $endDate: endDate,
            ...siteIdsParams,
        },
    };
};

export const parseReportsCountResult = ({ results }: WorkerExecResult): ReportsCountData => {
    const resultData = results[0];
    return {
        reportsCount: resultData.values[0][0] ?? 0,
        sitesCount: resultData.values[0][1] ?? 0,
    };
};
