import { Breakpoints, Icon, Palette, Spacing, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { CellStyled } from "../../Matrix.styles";

export const SiteCellStyled = styled(CellStyled)`
    background: transparent;
    color: ${Palette.Gray100};
    padding-left: ${Spacing.S}px;
    padding-right: ${Spacing.S}px;

    & > div {
        align-items: flex-start;
    }

    @media (max-width: ${Breakpoints.L}) {
        padding: 0;
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.M}px 0;
    }
`;

export const SiteIconStyled = styled(Icon)`
    margin: 0 5px 4px 0;
`;

export const TextSiteNameStyled = styled(Text)`
    width: 210px;

    @media (max-width: ${Breakpoints.XL}) {
        width: 150px;
    }

    @media (max-width: ${Breakpoints.L}) {
        width: 240px;
    }

    @media (max-width: ${Breakpoints.M}) {
        width: 150px;
    }
`;

export const TextTotalStyled = styled(Text)`
    text-wrap: nowrap;
    line-height: ${Spacing.S}px;
`;
