import { Breakpoints, Text, useHasMaxWidth } from "@secuis/ccp-react-components";

import { SiteHeaderCellStyled } from "./SiteHeaderCell.styles";

type SiteHeaderCellProps = {
    text: string;
};

export const SiteHeaderCell = ({ text }: SiteHeaderCellProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return (
        <>
            {!isMobile && (
                <SiteHeaderCellStyled>
                    <Text small color="secondary">
                        {text}
                    </Text>
                </SiteHeaderCellStyled>
            )}
        </>
    );
};
