import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateFilter } from "src/components/shared/filters";
import { selectDates } from "src/store/filter/FilterActions";
import FilterSelectors from "src/store/filter/FilterSelectors";

type Props = {
    onCalendarOpen?: () => void;
};

export const ReportsDateFilter = ({ onCalendarOpen }: Props) => {
    const dispatch = useDispatch();
    const preselectedStartDate = useSelector(FilterSelectors.getSelectedStartDate);
    const preselectedEndDate = useSelector(FilterSelectors.getSelectedEndDate);

    const onDateChange = useCallback(
        (startDate: Date, endDate: Date) => {
            dispatch(selectDates(startDate, endDate));
        },
        [dispatch],
    );

    return <DateFilter startDate={preselectedStartDate} endDate={preselectedEndDate} onDateChange={onDateChange} onCalendarOpen={onCalendarOpen} />;
};
