import { Breakpoints, ButtonIcon, Palette, Spacing } from "@secuis/ccp-react-components";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import { ReportsListItemType } from "../../../models/ReportModel";
import { VirtualListContext } from "../Reports.types";

export const ScrollTopButton = styled(ButtonIcon)`
    position: absolute;
    bottom: 32px;
    right: 64px;

    & svg {
        color: ${Palette.Navy800};
    }

    &:focus-visible {
        border-radius: 50%;
    }

    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.S}) {
        bottom: 24px;
        right: 64px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        bottom: 16px;
        right: 32px;
    }
`;
export const RelativeWrapper = styled.div`
    position: relative;
    z-index: 1;
`;
export const VirtuosoStyled = styled(Virtuoso<ReportsListItemType, VirtualListContext>)`
    height: 100%;
    flex: 1;
    & > div {
        padding: 0 40px;

        @media (max-width: ${Breakpoints.M}) {
            padding: 0 ${Spacing.M}px;
        }

        @media (max-width: ${Breakpoints.XS}) {
            padding: 0;
        }
    }
`;
