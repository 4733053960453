import React from "react";
import { useTranslation } from "react-i18next";

import { CategoryCell, SiteHeaderCell } from "../components";
import { DataCell, SiteCell } from "../components";
import { DataItem, IncidentData } from "../Matrix.types";

export interface Category {
    categoryKey: string;
}

export const useColumns = (visibleCategories: string[], visibleItems: DataItem[]) => {
    const { t } = useTranslation();
    const categories = visibleCategories.map((categoryKey) => ({ categoryKey }));

    const renderCategoryCell = (categoryKey: string) => {
        return <CategoryCell categoryKey={categoryKey} totalCount={calculateCategoryTotal(visibleItems, categoryKey)} />;
    };

    const renderDataCell = (categoryIncidentCount: number, totalIncidentCount: number) => {
        return <DataCell categoryIncidentCount={categoryIncidentCount} totalIncidentCount={totalIncidentCount} />;
    };

    const renderSiteCell = (name: string, total: number, index: number) => {
        return <SiteCell index={index} name={name} total={total} />;
    };

    const calculateCategoryTotal = (data: DataItem[], categoryKey: string): number => {
        return data.reduce((sum: number, site: DataItem) => {
            const incidentCounts = site[categoryKey] as IncidentData;
            return sum + (site[categoryKey] ? incidentCounts.categoryIncidentCount : 0);
        }, 0);
    };

    const sitesColumn = {
        id: "site",
        key: "site",
        HeaderCell: () => <SiteHeaderCell text={t("incidentMatrix.table.sites_column.header")} />,
        Cell: ({ cell, index }: { cell: React.ComponentProps<typeof SiteCell>; index: number }) => renderSiteCell(cell.name, cell.total, index),
    };

    const categoryColumns = categories.map((category) => ({
        id: category.categoryKey,
        key: category.categoryKey,
        HeaderCell: () => renderCategoryCell(category.categoryKey),
        Cell: ({ cell }: { cell: React.ComponentProps<typeof DataCell> }) => {
            return renderDataCell(cell.categoryIncidentCount, cell.totalIncidentCount);
        },
    }));

    const columns = [sitesColumn, ...categoryColumns];

    return columns;
};
