import { debounce } from "lodash";
import { useLayoutEffect, useRef, useState } from "react";

export const useTableScroll = () => {
    const [sitesLegend, setSitesLegend] = useState<{ name: string; index: string }[]>([]);

    const tableRef = useRef<HTMLDivElement>(null);
    const scrollPosition = useRef(0);
    const isManualScroll = useRef(false);

    useLayoutEffect(() => {
        setScrollPosition();
    }, [sitesLegend]);

    const updateSiteLegendItems = (tableBodyEl: HTMLDivElement) => {
        const { top: tableTop, bottom: tableBottom } = tableBodyEl.getBoundingClientRect();

        const visibleSites = Array.from(tableBodyEl.querySelectorAll(".siteCell"))
            .filter((siteElement: HTMLElement) => {
                const { top: siteTop, bottom: siteBottom } = siteElement.getBoundingClientRect();
                const siteHalfHeight = (siteBottom - siteTop) / 2; // used to show the site legend if the site element is only partialy visible
                return siteTop >= tableTop - siteHalfHeight && siteBottom <= tableBottom + siteHalfHeight;
            })
            .map((siteElement: HTMLElement) => {
                return {
                    name: siteElement.querySelector("[data-site-name]").getAttribute("data-site-name"),
                    index: siteElement.querySelector("[data-site-index]").getAttribute("data-site-index"),
                };
            });

        setSitesLegend(visibleSites);
    };

    const handleOnScroll = debounce((tableBodyEl: HTMLDivElement) => {
        if (!isManualScroll.current) {
            scrollPosition.current = tableBodyEl.scrollTop;
            updateSiteLegendItems(tableBodyEl);
        } else {
            isManualScroll.current = false;
        }
    }, 50);

    const setScrollPosition = () => {
        const tbody = tableRef.current?.querySelector('[role="rowgroup"]:nth-child(2)');
        if (tbody && scrollPosition.current > 10) {
            isManualScroll.current = true;
            tbody.scrollTo(0, scrollPosition.current);
        }
    };

    return { handleOnScroll, tableRef, sitesLegend };
};
