import { Breakpoints, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { resolveReportSubheader } from "src/helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { useOnEnterSpaceKeyDown } from "src/hooks/WebAccessibilityHooks";

import { SeverityBadge } from "../../../../components/Reports/SharedComponents/Badges";
import { getCombinedExceptionAndTourName, getReportTemplateShortTranslation } from "../../../../helpers/ReportHelper";
import { ReportsListItemType, ReportTemplateEnum, SiteZoneEnum } from "../../../../models/ReportModel";
import LocationsSelector from "../../../../store/locations/LocationsSelectors";
import { ColumnStack, ItemDetails, ListItem, ReportIdText, ReportNames, TemplateStack, UnreadDotWrapper, WrapabbleFlex } from "./ReportsListItem.styles";
import { ListItemCategory } from "./Shared/ListItemCategory";
import { ListItemCounters } from "./Shared/ListItemCounters";
import { ListItemDate } from "./Shared/ListItemDate";
import { ListItemPatrolTourSummary } from "./Shared/ListItemPatrolTourSummary";
import { UnreadDot } from "./Shared/SharedStyles";
import { ListItemVehicleLine } from "./VehicleInspection/ListItemVehicleLine";

type Props = {
    report: ReportsListItemType;
    isActive: boolean;
    unread: boolean;
    onClick: (id: string, locationId: string, type: string) => void;
};

export const ReportsListItem = ({ report, isActive, unread, onClick }: Props) => {
    const [localUnread, setLocalUnread] = useState(unread);
    const siteObject = useSelector(LocationsSelector.getAuthorizedLocationById(report.locationId));
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isPatrolTour = report.type === ReportTemplateEnum.patrolTour;

    const rootTemplate = useMemo(() => {
        if (isPatrolTour) {
            return report.properties.find((p) => p.key === "name")?.value;
        }
        if (report.type === ReportTemplateEnum.tourException || report.type === ReportTemplateEnum.tourMultiException) {
            const tourName = report.properties.find((p) => p.key === "tourName")?.value;
            return getCombinedExceptionAndTourName(report.type, tourName);
        }
        return getReportTemplateShortTranslation(report.type);
    }, [isPatrolTour, report]);

    useEffect(() => {
        setLocalUnread(unread);
    }, [unread]);

    const handleItemClick = useCallback(() => {
        setLocalUnread(false);
        onClick(report.id, report.locationId, report.type);
    }, [onClick, report.id, report.locationId, report.type]);

    const handleItemKeyDown = useOnEnterSpaceKeyDown(handleItemClick, true);

    const isPatrol = useMemo(() => {
        return report.type === ReportTemplateEnum.patrolTour;
    }, [report]);

    const isVehicleInspectionReport = report.type === ReportTemplateEnum.vehicleInspection;

    const siteLocationText = useMemo(() => {
        if (!report.siteLocation) {
            return null;
        }
        return SiteZoneEnum[report.siteLocation] ? t(`siteLocations.${SiteZoneEnum[report.siteLocation]}`) : report.siteLocation;
    }, [report, t]);

    const reportSubType = resolveReportSubheader(report.type, report.subType);

    return (
        <ListItem
            data-testid="desktop-report-list-item"
            key={report.id}
            active={isActive}
            tabIndex={0}
            role="button"
            onClick={handleItemClick}
            onKeyDown={handleItemKeyDown}
        >
            <ColumnStack>
                <ReportNames data-testid="report-name">
                    <UnreadDotWrapper>{localUnread && <UnreadDot data-testid="report-unread" />}</UnreadDotWrapper>
                    <TemplateStack alignItems="baseline" flexWrap="wrap">
                        <Text bold color="primary">
                            {rootTemplate}
                        </Text>
                        {reportSubType && !isPatrolTour && (
                            <Text micro bold color="neutral">
                                {reportSubType}
                            </Text>
                        )}
                    </TemplateStack>
                </ReportNames>
                {isPatrol && <ListItemPatrolTourSummary report={report} />}
                {report?.categoryLevel1 && (
                    <ItemDetails>
                        <Stack gap="XS" alignItems="center" direction="row" flexWrap="wrap">
                            <SeverityBadge data-testid="report-severity" severityLevel={report.severityLevel} />
                            <ListItemCategory report={report} />
                        </Stack>
                    </ItemDetails>
                )}
                {isVehicleInspectionReport && <ListItemVehicleLine report={report} />}
                <WrapabbleFlex>
                    <Text data-testid="list-item-site-name" micro bold>
                        {siteObject?.name}
                    </Text>
                    {siteLocationText && (
                        <Text data-testid="site-location-list-view" micro color="neutral">
                            {" "}
                            {`• ${siteLocationText}`}
                        </Text>
                    )}
                </WrapabbleFlex>
            </ColumnStack>
            <ColumnStack justifyContent="space-between">
                <Stack alignItems="flex-end" direction="column" gap={isMobile ? "0" : "XS"}>
                    <ListItemDate date={report.reportDateTime} />
                    {report.sourceId && !isPatrol && (
                        <ReportIdText micro color="neutral">{`${t("reportlist.subtitle.report")} # ${report.sourceId}`}</ReportIdText>
                    )}
                </Stack>
                <Stack alignItems="flex-end" justifyContent="flex-end" data-testid="list-item-image-comment-icons">
                    <ListItemCounters report={report} />
                </Stack>
            </ColumnStack>
        </ListItem>
    );
};
